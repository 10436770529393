import { Dispatch } from 'redux';
import {
    /*getUsers,*/ setUsers, addUser, deactivateUser, removeUser,
    reorderUserSilently,
    removeTypingUser, /*setOnlineUsersById,*/ setTypingUser,
} from '../../store/users.slice';
import { setGroups } from '../../store/groups.slice';
import { preLogin, login, logout, setLoggedInUserId } from '../../store/auth.slice';
import { /*setVoteeId,*/ setVoteeIdSilently, setStopVotingSilently } from '../../store/main.slice';
import {
    resetError as resetQuizSessionError,
    setVerifyingQuizSessionCode,
    setQuizSession,
    setError as setQuizSessionError,
} from '../../store/quizSession.slice';
import {
    setQuizDone,
    setAllQuizzesDone,
    setQuizHistory,
    setQuizzesHistory,
    setQuizStartTime,
    setQuestionsAnswersOnly,
    setVoteHistory,
} from '../../store/quiz.slice';
import { User, Group, QuizSession, Quiz, Message, RootState, QuestionAnswersOnly, VoteeHistory } from '../utilities/types';
import {
    //! initQuiz, 
    //! setAnswer, 
    setAnswerSilently,
    removeAnswer,
    //! removeUserAnswers,
    //!
    /*setLoading, setLoadingComplete,*/ setQuiz,
} from '../../store/quiz.slice';
import { addMessage } from '../../store/messages.slice';
import { debug } from '../utilities/debug';


// let isAlreadyInitialised = false;

export function initSocketListeners(socket: any, payload: any, dispatch: Dispatch, getState: () => RootState) {

    // TODO THIS STOP USERS GETTING REPEATED ON RECONNECTS AFTER SERVER RESTARTS (I SUPPOSE AS THE CLIENT RETAINED THE OLD CONNECTION SOCKET EVENTS???)
    // TODO HOWEVER WHY ARE THE PREVIOUS CLIENT SOCKETS STILL RECONNECTING WHEN A NEW SERVER STARTS UP???
    // if (isAlreadyInitialised) return;
    // isAlreadyInitialised = true;

//? socket.on('connect', () => {
        //! THIS CLOSURE RELIES ON THE PAYLOAD !!!
    socket.on('user connected', () => {
        debug(`user connected socket.connected=${JSON.stringify(socket.connected)} auth/preLogin: payload=${JSON.stringify(payload)}`);
        socket.emit('join room', payload);
    });

    socket.on('room joined', ({
            roomId, groups, users, quizSession
        }: {roomId: string, groups: Group[], users: User[], quizSession: QuizSession
    }) => {
        console.log(`room joined: groups=${JSON.stringify(groups)} users=${JSON.stringify(users)} quizSession=${JSON.stringify(quizSession)}`);

        dispatch(setVerifyingQuizSessionCode(false));
        dispatch(setQuizSession(quizSession));
        dispatch(resetQuizSessionError());
//? dispatch(getGroups(payload.roomId))...
        dispatch(setGroups(groups));
        dispatch(setUsers({roomId, users}));
//TODO THIS NEEDS TO NOW BE DONE WHEN THE QUIZ IS STARTED BY SOMEONE (A MODERATOR/ADMIN)
//TODO        dispatch(setQuiz(quiz));
        //! dispatch(setVoteeIdSilently(voteeId)); //? MAYBE NOT NECESSARY AS MUST ALSO BE SENT AFTER LOGIN (IN CASE CHANGES OR THIS USER BECOMES THE VOTEE?)
//TODO NOW THAT USERS ARE NO LONGER LOADED FROM LOGIN.TSX OR CHAT.TSX,
// WILL THIS LOCAL USER NEED ADDING ONCE THEY HAVE LOGGED IN (I.E. PROVIDED THEIR NAME ETC) ???
//?
//?            getUsers(payload.roomId);

// IF isAuthenticated IS SET HERE, AUTOMATICALLY DO A LOGIN
const isAuthenticated = getState().authState.isAuthenticated;
if (isAuthenticated) {
        // Automatically log user back in using previous stored user details
            //? const user = payload.user as User;
            const loginUser = getState().authState.currentUser as User;
        dispatch(login(loginUser));
}
else {
// If name provided (in URL query string) then bypass the login page and login immediately
const loginUser = getState().authState.currentUser as User;
//! alert(`loginUser=${JSON.stringify(loginUser)}`);
if (loginUser?.name) {
    dispatch(login(loginUser));
}
}

    });

    socket.on('invalid room code', () => {
        dispatch(setQuizSessionError("Le code pin n'est pas valide !"));
        dispatch(setVerifyingQuizSessionCode(false));
        //! dispatch(logout());
    });

    // Append a user every time a new one is registered
// TODO COULD THIS && 'user logged in' BE COMBINED ???
    socket.on('new user added', ({user, userIndex, oldUserId, voteeId, questionsAnswersOnly}: {user: User, userIndex: number, oldUserId: string, voteeId: string, questionsAnswersOnly: QuestionAnswersOnly[] | undefined}) => {
        debug(`new user added: user=${JSON.stringify(user)} voteeId=${voteeId}`);
        dispatch(addUser({user, userIndex}));
        dispatch(setVoteeIdSilently(voteeId));

        if (questionsAnswersOnly) {
            dispatch(setQuestionsAnswersOnly({questionsAnswersOnly, oldUserId}));
        }
    });

    socket.on('user logged in', ({user, userIndex, oldUserId, voteeId, questionsAnswersOnly}: {user: User, userIndex: number, oldUserId: string, voteeId: string, questionsAnswersOnly: QuestionAnswersOnly[] | undefined}) => {
        localStorage.setItem('user', JSON.stringify(user));
        dispatch(addUser({user, userIndex}));
        dispatch(setLoggedInUserId(user.id));
        debug('user logged in: voteeId='+voteeId);
        dispatch(setVoteeIdSilently(voteeId));

        if (questionsAnswersOnly) {
            dispatch(setQuestionsAnswersOnly({questionsAnswersOnly, oldUserId}));
        }
    });

    socket.on('remove user', (userId: string) => {
        console.log(`remove user: userId=${JSON.stringify(userId)}`);
        dispatch(removeUser(userId));
        //dispatch(setOnlineUsersById(onlineUsers))
    });

    socket.on('reorder user', ({userIndex, newUserIndex}: {userIndex: number, newUserIndex: number}) => {
        console.log(`reorder user: userIndex=${userIndex} newUserIndex=${newUserIndex}`);
        dispatch(reorderUserSilently({userIndex, newUserIndex}));
        //dispatch(setOnlineUsersById(onlineUsers))
    });

    socket.on('set votee id', (voteeId: string) => {
        dispatch(setVoteeIdSilently(voteeId));
    });

    // Need when network disconnects or the browser window gets closed as well as manual disconnections.
    // Only this user receives this event.
    socket.on('disconnect', () => {
        const currentUser = getState().authState.currentUser as User;
        console.log(`disconnect socket event! currentUser=${currentUser?.name}`);

        //! TEMP!!! SHOULD BY PASS LOGIN AND GO STRAIGHT BACK INTO GAME IF THIS EXISTS (UNLESS GAME NO LONGER EXISTS / STALE SESSION???)
        const oldLoggedInUserString = localStorage.getItem('user');
        debug('HOW TO DEAL WITH LOCALSTORAGE USER LOGIN DETAILS BEING STALE??? oldLoggedInUserString='+oldLoggedInUserString);

        // Automatically log user back in using previous stored user details
        if (oldLoggedInUserString) {
            const __loggedInUser__ = JSON.parse(oldLoggedInUserString);
            const loginUser = {
                ...currentUser,
                id: __loggedInUser__.id,
            };
            // isAuthenticated SHOULD STILL BE SET HERE, SO PRELOGIN WILL AUTOMATICALLY DO A LOGIN SUBSEQUENTLY
            // alert(`disconnect: ABOUT TO auth/preLogin (COMMENTED OUT!!!)`);
// TODO DON'T DO THIS IF USER DIDN'T INITIATE THE DISCONNECT!?!!!
            dispatch(preLogin(loginUser));
        }

        // Note 'deactivate user' will be emitted by the server where all
        // event listeners will be finally removed for the socket.
    });

    socket.on('deactivate user', (userId: string) => {
        console.log(`deactivate user: userId=${JSON.stringify(userId)}`);
        dispatch(deactivateUser(userId));
        //dispatch(setOnlineUsersById(onlineUsers))

        // Remove all event listeners for the socket to prevent problems with
        // multiple connection events on reconnections.
        //! socket.removeAllListeners();
    });

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // Append a message every time a new one comes in
    socket.on('receive message', (message: Message) => {
        dispatch(addMessage(message));
    });

    // Remove if some user stops typing
    socket.on('user stopped typing...', (name: string) => {
        dispatch(removeTypingUser(name));
    });

    // Add if some user starts typing
    socket.on('user starts typing...', (name: string) => {
        dispatch(setTypingUser(name));
    });

    // Set answer
    socket.on('set answer', ({questionId, userId, choice, createdAt}: {questionId: string, userId: string, choice: number, createdAt: number}) => {
        console.log(`set answer createdAt=${createdAt}`);
        dispatch(setAnswerSilently({questionId, userId, choice, createdAt}));
    });

    // Remove answer
    socket.on('remove answer', ({questionId, userId, abstained}: {questionId: string, userId: string, abstained: boolean}) => {
        dispatch(removeAnswer({questionId, userId, abstained}));
    });

/*
    // Update quiz
    socket.on('update quiz', ({userId, quiz}: {userId: string, quiz: Quiz}) => {
        console.log(`update quiz (client side): quiz=${JSON.stringify(quiz)}`);
        // TODO TEMP NEEDED AS BROADCASTS TO ALL CLIENTS INCLUDING THE ONE WHO SENT THIS (HOW TO EXCLUDE SENDER?)
        const currentUser = getState().authState.currentUser;
        const currentUserId = currentUser!.name; //! TEMP!!!
        if (userId == currentUserId) console.log("IT'S A RESPONSE TO MY UPDATE QUIZ! IGNORING!");
        if (userId != currentUserId)
            dispatch(updateQuiz(quiz));
    });
*/

    socket.on('current voting finished', () => {
        dispatch(setStopVotingSilently());
    });

    // socket.on('next votee', ({quiz, voteeId, startingQuiz, quizzesDone}: {quiz: Quiz, voteeId: string, startingQuiz: boolean, quizzesDone: boolean}) => {
    //     console.log('next votee: voteeId=', voteeId, 'quiz=', quiz);
    //     if (startingQuiz) {
    //         debug('NEW QUIZ STARTING!');
    //         dispatch(setQuizDone());
    //     }
    //     if (quizzesDone) {
    //         debug('ALL QUIZZES FINISHED!');
    //         dispatch(setQuizDone());
    //     }
    //     dispatch(setQuiz(quiz));
    //     dispatch(setVoteeIdSilently(voteeId));
    // });

    socket.on('next votee', ({quiz, voteeId}: {quiz: Quiz, voteeId: string}) => {
        dispatch(setQuiz(quiz));
//!        dispatch(setQuizDone(false));
        dispatch(setVoteeIdSilently(voteeId));
    });

    socket.on('new quiz started', ({quiz, voteeId, voteHistory}: {quiz: Quiz, voteeId: string, voteHistory: VoteeHistory[]}) => {
        dispatch(setQuiz(quiz));
//!        dispatch(setQuizDone(false));
        dispatch(setVoteeIdSilently(voteeId));
// TODO WHY ARE ALL CLIENTS GETTING SENT THIS MESSAGE WHEN SOMEBODY JOINS QUIZ???
//? alert('socket.on(\'new quiz started\'...)');
        dispatch(setVoteHistory(voteHistory));
    });

    socket.on('current quiz done', ({quizHistory}: {quizHistory: any}) => {
        //! console.log(`current quiz done: quizHistory=`, quizHistory);
        dispatch(setQuizHistory(quizHistory));
        dispatch(setQuizDone());
    });

    socket.on('all quizzes done', ({quizzesHistory}: {quizzesHistory: any}) => {
        //! console.log(`all quizzes done: quizzesHistory=`, quizzesHistory);
        dispatch(setQuizzesHistory(quizzesHistory));
        dispatch(setAllQuizzesDone());
    });

    socket.on('change quiz start time', ({startTime}: {startTime: number}) => {
        console.log(`change quiz start time: startTime=${startTime}`);
        dispatch(setQuizStartTime(startTime));
    });
}
