import Lottie from 'react-lottie';
import { User } from '../app/utilities/types';

import './UserAvatar.css';

// TODO COULD THIS BE LOADED ON DEMAND DYNAMICALLY?
import emojiKissingHeart from '../lottie/users/default/emoji-kissing-heart.json';
import emojiSmileyCat from '../lottie/users/default/emoji-smiley-cat.json';

// TODO COULD BE USED FOR GROUP AVATARS ALSO
export interface Avatar {
  name: string,
  image?: string,
  lottie?: any,
  tags: string[],
}

/**
 * Ref: https://app.lottiefiles.com/
 * Ref: https://lottiefiles.com/
 * Ref: https://googlefonts.github.io/noto-emoji-animation/
 */
export const userAvatars: Record<string, Avatar> = {
  "DEFAULT": {
    name: "Default",
    image: "default/emoji-kissing-heart.json",
    lottie: emojiKissingHeart,
    tags: ["emoji", "human"],
  },
  "EMOJI_1": {
    name: "Kissing heart",
    image: "default/emoji-kissing-heart.json",
    lottie: emojiKissingHeart,
    tags: ["emoji", "human"],
  },
  "EMOJI_2": {
    name: "Smiley cat",
    image: "default/emoji-smiley-cat.json",
    lottie: emojiSmileyCat,
    tags: ["emoji", "animal"],
  },
  "HP_1": {
    name: "Harry Potter",
    image: "harry-potter/harry-potter.jpg",
    tags: ["harry potter", "gryffindor", "human", "half blood", "male"],
    // TODO Make keys an 'and' relation, so:
    // TODO gryffindor + male will filter all males from house Gryffindor, whereas
    // TODO gryffindor + male + human will filter all males from house Gryffindor that are human only
    // TODO gryffindor + half blood + male + human will filter all males from house Gryffindor that are human or half blood
    // TODO Also maybe these should all be IDs?
    // tags: [{
    //     "harry potter": ["gryffindor", "half blood"], // Group theme
    //     "gender": ["male"],
    //     "species": ["human"],
    // }],
  },
  "HP_2": {
    name: "Hermione Granger",
    image: "harry-potter/hermione-granger.jpg",
    tags: ["harry potter", "gryffindor", "human", "muggle", "female"],
  },
  "HP_3": {
    name: "Ronald Weasley",
    image: "harry-potter/ronald-weasley.jpg",
    tags: ["harry potter", "gryffindor", "human", "muggle", "male"],
  },
  "HP_4": {
    name: "Rubeus Hagrid",
    image: "harry-potter/hagrid.jpg",
    tags: ["harry potter", "gryffindor", "human", "giant", "male"],
  },
  "HP_5": {
    name: "Professor Severus Snape",
    image: "harry-potter/professor-snape.jpg",
    tags: ["harry potter", "slytherin", "human", "half blood", "male"],
  },
  "HP_6": {
    name: "Dobby",
    image: "harry-potter/dobby.jpg",
    tags: ["harry potter", "hufflepuff", "elf", "male"],
  },
  "HP_6b": {
    name: "Dobby DEUX",
    image: "default/emoji-kissing-heart.json",
    lottie: emojiKissingHeart,
    tags: ["harry potter", "hufflepuff", "elf", "male"],
  },
  "HP_7": {
    name: "Dobby 2",
    image: "harry-potter/dobby.jpg",
    tags: ["harry potter", "ravenclaw", "elf", "male"],
  },
};

export interface UserAvatarProps {
  key: string,
  avatarId?: string,
  name?: string,
  hasFocus?: boolean,
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
  style?: object,
  children?: React.ReactNode,
}

const UserAvatar: React.FC<UserAvatarProps> = ({key, avatarId, name, hasFocus = false, onClick, style, children}) => {
  const {
    image,
    lottie,
  } = userAvatars[avatarId ?? "DEFAULT"];

  return (
    <div
      key={key}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        margin: 5,
        // border: id === user.avatarId ? "1px dashed currentColor" : "none",
// background: "red",
flex: "1 1 0",
      }}
    >
      <div
        data-avatarid={avatarId}
        onClick={onClick}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          // margin: 5,
          ...style,
        }}
      >
        {lottie &&
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: lottie,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            }}
            height={80}
            width={80}
            style={{
              // border: hasFocus ? "4px solid rgb(230 140 63 / 80%)" : "none",
              border: hasFocus ? "4px solid rgb(230 140 63 / 80%)" : "4px solid transparent",
              borderRadius: 10,
              overflow: "visible",
            }}
          />
        }

        {image && !lottie &&
          <img
            src={`/assets/images/users/${image}`}
            alt={`Utilisateur ${name}`}
            className="bounce-in"
            style={{
              width: 80,
              height: 80,
              borderRadius: 100,
              boxShadow: hasFocus ? "0px 0px 0px 4px rgb(230 140 63 / 80%)" : "0px 0px 0px 3px rgb(0 0 0 / 80%)",
              padding: 1,
              margin: 2,
            }}
          />
        }

        {children}

        <div style={{margin: 5, fontWeight: "normal", whiteSpace: "nowrap", background: "#333", color: "#eee", borderRadius: 8, padding: "2px 5px"}}>
          {name}
        </div>
      </div>
    </div>
  );
};

export default UserAvatar;
