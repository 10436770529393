import { 
  IonAccordion,
  IonAccordionGroup,
  IonReorder,
  IonReorderGroup,
  IonItem, 
  ItemReorderEventDetail,
  IonBadge,
} from '@ionic/react';
import { caretDownCircle, caretForward, caretBack } from 'ionicons/icons';
import './GroupInfo.css';
import { useState } from 'react';
import { User, Group, Quiz, IconType, InfoContext } from '../app/utilities/types';
import { UserInfo } from './UserInfo';
import { VoteIcon } from './VoteIcons';

interface GroupInfoProps {
  className: string,
  group: Group,
  users: User[],
  quiz: Quiz | null,
  currentUser: User | null,
  voteeId?: string,
  typingUsers: string[],
  infoContext: InfoContext,
}

export const GroupInfo: React.FC<GroupInfoProps> = ({ group, users, quiz, currentUser, voteeId, typingUsers, infoContext }) => {
  const [showUsers, setShowUsers] = useState(false);

  const groupUsers = users.filter(user => user.groupId === group.id);

  // TODO DRY WITH UserInfo.tsx
  let lastTimeVoted = 0;
  let percentageQuestionsVotedFor = 0;
  if (quiz) {
    //! let numQuestions = 0;
    const numQuestionsVotedFor = quiz.questions.reduce((acc, question) => {
      const userAnswers = question.answers.filter(answer => groupUsers.find(user => answer.userId === user.id));
      // Note answer could have no choice but just be flagged as abstained
      userAnswers.forEach(userAnswer => {
        if (userAnswer.createdAt && userAnswer.createdAt > lastTimeVoted) {
          lastTimeVoted = userAnswer.createdAt;
        }
      });
      //! ++numQuestions;
      if (userAnswers.length) {
        ++acc; //! acc += usersAnswer.choice ? 1 : 0;
      }
      return acc;
    }, 0);
    //! console.log(`numQuestions=${numQuestions} numQuestionsVotedFor=${numQuestionsVotedFor}`);
    if (numQuestionsVotedFor) {
      percentageQuestionsVotedFor = (numQuestionsVotedFor / quiz.questions.length) * 100;
    }
  }

  if (infoContext !== "sidebar" && infoContext !== "lobby") {
    return (
      <span
        className="group-info"
        style={{
          // background: '#352fcf',
        }}
      >
          {/* {infoContext === "main" ? "groupe " : ""}{group.name} */}
          {group.name}
      </span>
    );
  }

  function formatLastTimeVoted() {
    if (quiz) {
      const relTime = Math.floor((lastTimeVoted - quiz.startTime) / 1000);
      const mins = Math.floor(relTime / 60);
      const secs = relTime % 60;
      return `${mins ? mins : ''} ${secs < 10 && mins ? '0' : ''}${secs}s`;
    }
    return "";
  }

  const isVotee = groupUsers.some((user) => user.id === voteeId);

  return (

<IonAccordion value={group.id} toggleIcon={caretDownCircle} toggleIconSlot="start" className={`ion-accordion-toggle-icon group-info-container ${isVotee ? 'is-votee' : ''}`}>

<IonItem
  slot="header"
  className={isVotee ? 'is-votee' : ''}
  color={isVotee ? 'light' : ''}
>

    <div
      // key={group.id}
      className="group-info mx-6 my-2 text-white text-opacity-70 bg-blue-dark rounded-md"
      style={{
        // background: '#352fcf',
        // display: 'inline-block',
      }}
      data-progress={percentageQuestionsVotedFor}
    >
      <div slot="content" className="progress-container XXprogress-translucent" style={{'--progress': percentageQuestionsVotedFor + "%"} as React.CSSProperties}>
        {/* <div className="progress-bar">
          <div className="progress-bar-bg"></div>
        </div> */}

{/* TEMP MOVE PADDING TO '.progress-container > *' */}
{/* TODO DETERMINE GROUP VOTE PROGRESS ACCORDING TO ITS POLICY (MIN/MAX VOTERS VOTED ETC) */}
{/* <div className="p-3"> */}
        <div className="XXflex XXjustify-between XXitems-center">
        <div className="XXjustify-between XXitems-center" style={{display: 'flex', width: '100%'}}>
            <div className={`h-2 w-2 mr-2 rounded-full inline-block ${groupUsers.some(user => user.online) ? 'bg-green-400' : 'bg-blue-light'}`}></div>
            <span style={{flex: 1}}>
              {group.name}
              <IonBadge style={{fontSize: '0.6rem', marginLeft: 5}} color="secondary">{groupUsers.length}</IonBadge>
            </span>
            {percentageQuestionsVotedFor === 100 &&
              <IonBadge style={{flex: 'none', fontSize: '0.6rem', marginLeft: 5}} color="light">{formatLastTimeVoted()}</IonBadge>
            }
          </div>

          {/* TODO */}
          {/* <IonItem slot="end">
          <VoteIcon
            className="group-users-info-show-icon"
            iconType={(showUsers ? "crossCircle" : "checkCircleFill") as IconType}
            isNonRatingIconHack={true}
            size={22}
            onClick={() => setShowUsers(!showUsers)
          }/>
          </IonItem> */}
        </div>
      </div>


        {/* {infoContext !== "leaderboard" &&  */}
          {/* <div className={`group-users-info ${showUsers ? 'visible' : 'hidden'}`}>
            {groupUsers.map((user) => (
              <UserInfo
                className={`user-info entity-info info-context-${infoContext}`}
                key={user.id}
                user={user}
                quiz={quiz}
                currentUser={currentUser}
                typingUsers={typingUsers}
                infoContext={infoContext}
              />
            ))}
          </div> */}
        {/* } */}

{/* </div> */}

      {/* </div> */}


    </div>


{/* <IonReorder slot="end"></IonReorder> */}
<IonReorder slot=""></IonReorder>

</IonItem>


<div className="XXion-padding" slot="content">
    <div className={`group-users-info ${showUsers ? 'visible' : 'hidden'}`}>
      {/* The reorder gesture is disabled by default, enable it to drag and drop items
      TODO WRAP ONLY FOR ADMINS */}
      <IonAccordionGroup multiple={true}>
      <IonReorderGroup disabled={currentUser!.role !== "admin"} onIonItemReorder={handleReorder}>

      {groupUsers.map((user) => (

        <UserInfo
          className={`user-info entity-info info-context-${infoContext}`}
          key={user.id}
          user={user}
          quiz={quiz}
          currentUser={currentUser}
          // The group is considered the "votee" if one of its members is the designated votee
          voteeId={undefined}
          typingUsers={typingUsers}
          infoContext={infoContext}
        />

      ))}

      </IonReorderGroup>
      </IonAccordionGroup>
    </div>
</div>


</IonAccordion>

  );
};

function handleReorder(event: CustomEvent<ItemReorderEventDetail>) {
  // The `from` and `to` properties contain the index of the item
  // when the drag started and ended, respectively
  console.log('Dragged from index', event.detail.from, 'to', event.detail.to);

  // Ensure event doesn't cause parent accordions to react
  event.stopPropagation();

  // Finish the reorder and position the item in the DOM based on
  // where the gesture ended. This method can also be called directly
  // by the reorder group
  event.detail.complete();
  //! dispatch(reorderUser({userIndex: event.detail.from, newUserIndex: event.detail.to}));
}
