import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QuizSession, QuizSessionState } from '../app/utilities/types';

const quizSessionSlice = createSlice({
  name: 'quizSession',
  initialState: {
    quizSession: null,
    loading: false,
    error: null,
  } as QuizSessionState,
  reducers: {
    // TODO USE getInitialState()
    resetState: (state) => {
      state.quizSession = null;
      state.loading = false;
      state.error = null;
    },
    setQuizSession: (state, {payload}: PayloadAction<QuizSession>) => {
      console.log(`setQuizSession: payload=${JSON.stringify(payload)}`);
      state.quizSession = payload;
    },
    setLoading: (state) => {
      state.loading = true;
    },
    setLoadingComplete: (state) => {
      state.loading = false;
    },
    resetError: (state) => {
      state.error = null;
    },
    setError: (state, {payload: error}: PayloadAction<string>) => {
      state.error = error;
    },
    setVerifyingQuizSessionCode(state, {payload: isVerifying}: PayloadAction<boolean>) {
      state.loading = false;
      if (isVerifying) {
        state.loading = true;
        state.error = null;
      }
    }
  },
});

export const {
  resetState,
  setQuizSession,
  setLoading,
  setLoadingComplete,
  resetError,
  setError,
  setVerifyingQuizSessionCode,
} = quizSessionSlice.actions;

export default quizSessionSlice.reducer;
