import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from '../app/utilities/types';

const initialState: AuthState = {
  currentUser: null,
  name: undefined,
  hasValidRoomCode: false,
  roomId: "",
  isAuthenticated: false,
  role: "user",
  isPlaying: false,
  error: null,
  isAutoLogin: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    preLogin: (state, { payload }: PayloadAction<any>) => {
      state.name = payload.name;
      state.roomId = payload.roomId;
      state.role = payload.role;
      state.isPlaying = payload.isPlaying;
      state.hasValidRoomCode = true;
    },
    login: (state, { payload }: PayloadAction<any>) => {
      state.currentUser = payload;
      state.isAuthenticated = true;
    },
    setLoggedInUserId: (state, { payload }: PayloadAction<string>) => {
      state.currentUser!.id = payload;
    },
    setAutoLogin: (state, { payload }: PayloadAction<boolean>) => {
      state.isAutoLogin = payload;
    },
    logout: (state) => {
// TODO NOT SURE IMPLICATIONS OF NOT SETTING currentUser TO NULL ON DISCONNECT -- TEST & TEST WELL!!!
// TODO BUT IT DOES ENABLE A USER TO RETURN TO THE LOGIN AND SHOW OTHER USERS & TEAMS)
//?!      state.currentUser = null;
      state.roomId = "";
      state.hasValidRoomCode = false;
      state.isAuthenticated = false;
    },
    fakeDisconnect: (state) => {
    },
  },
});

export const {
  preLogin,
  login,
  setLoggedInUserId,
  setAutoLogin,
  logout,
  fakeDisconnect,
} = authSlice.actions;

export default authSlice.reducer;
