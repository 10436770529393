// TODO REMOVE DEPRECIATED getDefaultMiddleware
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import { rootReducer } from './rootReducer';
import socketMiddleware from '../app/middleware/socketMiddleware';
import otherStoreMiddleware from '../app/middleware/otherStoreMiddleware';
import SocketClient from '../app/api/SocketClient';

const socket = new SocketClient();

export const store = configureStore({
  reducer: rootReducer,
  middleware: [socketMiddleware(socket), otherStoreMiddleware(), ...getDefaultMiddleware()],
});
