import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ellipse, square, triangle, logIn } from 'ionicons/icons';
import Page from './components/Page';
import PreLogin from './pages/PreLogin';
import Login from './pages/Login';
import Vote from './pages/Vote';
import LeaderBoard from './pages/LeaderBoard';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import './App.css';

setupIonicReact();

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet>
          <Page>
            {/* <Route exact path="/pre-login"> */}
            <Route exact path="/">
              <PreLogin />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/vote">
              <Vote />
            </Route>
            <Route exact path="/scores">
              <LeaderBoard />
            </Route>
            {/* <Route exact path="/">
              <Redirect to="/pre-login" />
            </Route> */}
          </Page>
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          {/* <IonTabButton tab="pre-login" href="/pre-login">
            <IonIcon icon={logIn} />
            <IonLabel>Pre login</IonLabel>
          </IonTabButton> */}
          <IonTabButton tab="login" href="/login">
            <IonIcon icon={square} />
            <IonLabel>Login</IonLabel>
          </IonTabButton>
          <IonTabButton tab="vote" href="/vote">
            <IonIcon icon={triangle} />
            <IonLabel>Vote</IonLabel>
          </IonTabButton>
          <IonTabButton tab="scores" href="/scores">
            <IonIcon icon={ellipse} />
            <IonLabel>Scores</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  </IonApp>
);

export default App;
