import {
  IonApp,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonFooter,
  IonMenu,
  IonList,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonListHeader,
  IonCardContent,
  IonMenuToggle,
  IonIcon,
  IonBadge,
  IonLabel,
  IonPage,
  IonSplitPane,
  IonButtons,
  IonButton,
  IonItem,
  IonTabBar,
  IonTabs,
  IonTabButton,
  IonRouterOutlet,
  IonRippleEffect,
} from '@ionic/react';

import './VoteArea.css';

//! TEMP SO BUILD/DEPLOY WON'T FAIL
/* eslint-disable */


import React, { useEffect, useRef, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, User, Group, Quiz, Question } from '../app/utilities/types';
import { EntityInfo } from './EntityInfo';
import { Votes } from './Votes';
import { Votometer } from './Votometer';
import { scrollTo } from '../store/main.slice';
import Particles from '../app/utilities/particles';

function countQuestionsVotedFor(quiz: Quiz, user: User) {
  return quiz.questions.reduce(
    (numQuestionsVotedOn: number, question: Question) => numQuestionsVotedOn +
      (question.answers.some(answer => answer.userId === user.id) ? 1 : 0),
    0,
  );
}

interface VoteAreaProps {
  users: User[],
  groups: Group[],
  quiz: Quiz,
  typingUsers: string[],
}

export const VoteArea: React.FC<VoteAreaProps> = ({
  users,
  groups,
  quiz,
  typingUsers,
}) => {
  const { currentUser } = useSelector((state: RootState) => state.authState);

  const { votee, voteeId, stopVoting } = useSelector(
    (state: RootState) => state.mainState
  );

  const dispatch = useDispatch();

  const scrollToRef = useRef<HTMLDivElement>(null);
  //! const scrollToRef2 = useRef<HTMLIonCardElement>(null);
//const questionCardRefs = useRef<HTMLIonCardElement[]>([]);
const questionCardRefs = useRef<any[]>([]);

const lastQuestionVotedForIndexRef = useRef<number>(-1);

//???
  // const { groups } = useSelector(
  //   (state: RootState) => state.groupsState
  // );

//!  const [votee, setVotee] = useState<User>();

const isAdmin = currentUser!.role === "admin"
  const isPlaying = currentUser!.isPlaying;
  // TODO MOVE THIS TO STATE? OR UTILS? OR HOOKS? (SEE Page.tsx ALSO)
  const isVoter = currentUser?.id !== voteeId && (!currentUser?.groupId || currentUser.groupId !== votee?.groupId);
  const isVotee = !isVoter;

//TODO MOVE THESE INTO QUIZ (SERVER SIDE)
  const canVoteForSelf = !true;

  useEffect(() => {
    if (stopVoting) {
      // TODO SCROLL MORE INTELLIGENTLY - TO TOP OF VOTE (Critères title) IF NOT AT TOP OF VOTE AREA
      // TODO ALSO, AS NEW CRITERES COME ON FROM THE BOTTOM, SCROLL UP IF THEY ARE NOT FULLY VISIBLE
      //!dispatch(scrollTo());
      dispatch(scrollTo({ref: null}));
      Particles.confetti(document.body)
    }
  }, [stopVoting]);

  const showAllQuestionsInitially = !true;

  // TODO MOVE THIS TO STATE? OR UTILS? OR HOOKS?
  const hasVotedForAllQuestions = isVoter && countQuestionsVotedFor(quiz, currentUser!) === quiz.questions.length;

let totalMaxVoters = 0;
let totalNumVoted = 0;

const maxVoters = users.reduce((acc, user) => acc + (user.isPlaying && user.id !== votee?.id && (user.groupId == null || user.groupId !== votee?.groupId) ? 1 : 0), 0);

const percentageVotersVotedFor: any = {};
let lastQuestionVotedForIndex = -1;
quiz.questions.forEach((question, i) => {
  const numVoted = question.answers.reduce((acc, answer) => acc + 1, 0);

  const percentage = numVoted / maxVoters * 100;
  percentageVotersVotedFor[question.id] = percentage;

  if (percentage) {
    lastQuestionVotedForIndex = i;
  }

  totalMaxVoters += maxVoters;
  totalNumVoted += numVoted;
});

lastQuestionVotedForIndexRef.current = lastQuestionVotedForIndex + 1;


//const questionCardRefs: {[string]: HTMLIonCardElement} = {};
//const questionCardRefs: Record<string, HTMLIonCardElement> = {};
//const questionCardRefs: any = {};
questionCardRefs.current = quiz.questions.map((question, i) => questionCardRefs.current[i] ?? createRef());

useEffect(() => {
    const scrollToRef22 = questionCardRefs.current[lastQuestionVotedForIndexRef.current]
    if (scrollToRef22?.current) {
      dispatch(scrollTo({ref: scrollToRef22, duration: 1000}));
      //scrollToRef22.current.style.background = "lime";
    }
}, [lastQuestionVotedForIndexRef.current]);

let percentageVotersVotedForAll = totalNumVoted / totalMaxVoters * 100;
//console.log('quiz=', quiz);
//console.log(`totalNumVoted=${totalNumVoted} totalMaxVoters=${totalMaxVoters} percentageVotersVotedForAll=${percentageVotersVotedForAll}`);

  if (isAdmin && !isPlaying) {
    return (
      <Votometer quiz={quiz} users={users} />
    );
  }

  return (
    <div className="vote-area">
      <div className="vote-desc">
        <p>{quiz.description}</p>
        <img src="/assets/machine-à-oeufs.jpg" />
      </div>

<div ref={scrollToRef} className="flex flex-row">

<div style={{width: '100%'}}>
      <div className="votee-info">
        <div className="progress-container" style={{'--progress': percentageVotersVotedForAll + "%"} as React.CSSProperties}>
          <div className="progress-bar">
            <div className="progress-bar-bg"></div>
          </div>
          <h3 style={{
            width: "100%",
            textAlign: "center",
            fontSize: isAdmin ? "3em" : "1em",
          }}>
            {isAdmin && <>
              {hasVotedForAllQuestions
                ? <span style={{fontSize: '.7em', lineHeight: '4px'}}>A voté !</span>
                : (votee && <>
                    <span>Vote en cours : </span>
                    <EntityInfo
                      key={votee!.id}
                      user={votee!}
                      groups={groups}
                      users={users}
                      quiz={quiz}
                      currentUser={currentUser}
                      voteeId={voteeId}
                      typingUsers={typingUsers}
                      infoContext="main"
                    />
                    <span> - À vos votes !</span>
                  </>)
              }
            </>}
            {isPlaying && !stopVoting && (isVoter
              ? (hasVotedForAllQuestions
                  // ? <span style={{fontSize: '.7em', lineHeight: '4px'}}>Veuillez patienter lorsque les autres font leurs votes, vous pourrez toujours modifier vos votes si vous en changez votre avis !</span>
                  ? <span style={{fontSize: '.7em', lineHeight: '4px'}}>A voté !</span>
                  // : `Votez pour ${votee?.name} !`)
                  : (votee && <>
                      <span>Votez pour </span>
                      <EntityInfo
                        key={votee!.id}
                        user={votee!}
                        groups={groups}
                        users={users}
                        quiz={quiz}
                        currentUser={currentUser}
                        voteeId={voteeId}
                        typingUsers={typingUsers}
                        infoContext="main"
                      />
                      <span> !</span>
                    </>)
                )
              : (canVoteForSelf
                  ? `Votez pour vous-même !`
                  : `On vote pour vous...`)
            )}
          </h3>
        </div>
      </div>

      <h3 className="questions-title">Critères :{stopVoting ? " Les moyens des votes" : ""}</h3>

      <IonList>
        {quiz.questions.map((question, i) => (
          <IonCard
            ref={questionCardRefs.current[i]}
            key={i} // TODO SHOULD BE question.id WHEN THEY EVENTUALLY HAVE IDs
            className={`question-container ${showAllQuestionsInitially || i <= lastQuestionVotedForIndex ? "show" : ""}`}
            style={{'--delay-factor': showAllQuestionsInitially || i <= lastQuestionVotedForIndexRef.current + 1 ? i : 0} as React.CSSProperties}
          >
            {/* <IonCardHeader>
              <IonCardSubtitle>Card Subtitle</IonCardSubtitle>
              <IonCardTitle>Card Title</IonCardTitle>
            </IonCardHeader> */}

            <IonCardContent
              className="progress-container progress-container--line"
              style={{'--progress': percentageVotersVotedFor[question.id] + "%"} as React.CSSProperties}
            >
              <div className="question">
                <div className="question-title">{question.title}</div>
                <Votes
                  question={question}
                  localUser={currentUser!}
                  isVoting={isPlaying && (!isVotee || canVoteForSelf) && !stopVoting}
                  users={users}
                  groups={groups}
                />
              </div>
            </IonCardContent>
          </IonCard>
        ))}
      </IonList>
</div>
</div>
    </div>
  );
};
