import {
  IonHeader,
  IonToolbar,
  IonMenuToggle,
  IonIcon,
  IonBadge,
  IonButtons,
  IonButton,
} from '@ionic/react';

import './Header.css';
import { home, menu, logOutOutline, playSkipBackOutline, playSkipForwardOutline, playOutline, pauseOutline, refreshOutline } from 'ionicons/icons';
import logo from '../app/assets/classvote-logo.png';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../app/utilities/types';
import { setStopVoting, stopVotingAndGoBackToPreviousVote } from '../store/main.slice';
import { resetQuizStartTime } from '../store/quiz.slice';
import { fakeDisconnect } from '../store/auth.slice';

interface Props {
  onLogout: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  children?: React.ReactNode,
};

export const Header = ({ onLogout, children }: Props) => {
  const dispatch = useDispatch();

  const { currentUser, roomId } = useSelector((state: RootState) => state.authState);

  const [votingPaused, setVotingPaused] = useState(false);

  function onFakeDisconnect() {
    dispatch(fakeDisconnect());
  }

  function onFakeReconnect() {
    // TODO DO WHAT socket.on('disconnect') AUTOMATICALLY DOES
    //? dispatch(preLogin());
  }

  function onPauseVoting() {
    setVotingPaused(votingPaused => !votingPaused);
  }

  function onStopVoting() {
    dispatch(setStopVoting());
  }

  function onStopVotingAndGoBackToPreviousVote() {
    dispatch(stopVotingAndGoBackToPreviousVote());
  }

  function onResetQuizStart() {
    dispatch(resetQuizStartTime());
  }

  return (
    <IonHeader className="header">
      <IonToolbar className="toolbar">
        <IonButtons slot="start">
          <IonMenuToggle>
            <IonButton>
              <IonIcon slot="icon-only" icon={menu} />
            </IonButton>
          </IonMenuToggle>
        </IonButtons>

        {/* <IonTitle>{roomId}</IonTitle> */}

        <IonButtons slot="end">
          {currentUser?.role === "admin" && <>
            {/* <IonButton onClick={onStopVotingAndGoBackToPreviousVote}>
              <IonIcon slot="icon-only" icon={playSkipBackOutline} />
            </IonButton>
            <IonButton onClick={onPauseVoting}>
              <IonIcon slot="icon-only" icon={votingPaused ? playOutline : pauseOutline} />
            </IonButton> */}
            <IonButton color="primary" fill="outline" onClick={onStopVoting} style={{margin: 5}}>
              Terminer le voting
              <IonIcon slot="end" icon={playSkipForwardOutline} />
            </IonButton>
            <IonButton color="primary" fill="outline" onClick={onResetQuizStart} style={{margin: 5}}>
              Remettre le<br /> compteur au début
              <IonIcon slot="end" icon={refreshOutline} />
            </IonButton>
          </>}
          {/*<IonButton onClick={onFakeDisconnect}>
            Fake Disconnect
          </IonButton>*/}

          {/* <IonButton className="ion-activatable ripple-parent"
            // .ripple-parent
            style={{
              position: 'relative',
              overflow: 'hidden',
            }}
            onClick={onLogout as any}
          >
            Se déconnecter
          <IonRippleEffect />
        </IonButton> */}
        {roomId &&
          <IonBadge color="secondary" style={{fontSize: "1.6em", margin: 5, padding: "2px 5px"}}>
            Code PIN : {roomId}
          </IonBadge>
        }
          <IonButton className="ion-activatable" onClick={onLogout as any}>
            <IonIcon slot="icon-only" icon={logOutOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>

      <div className="content">
        <div className="content-left">
          <div className="classvote-logo">
            <img src={logo} alt="Classvote logo" />
          </div>
        </div>
        <div className="content-centre">
          {children}
        </div>
        <div className="content-right">
        </div>
      </div>
    </IonHeader>
  );
};
