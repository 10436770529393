import {
  IonBadge,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonRadioGroup,
  IonListHeader,
  IonLabel,
  IonItem,
  IonRadio,
  IonToggle,
  IonItemDivider,
  IonCard,
  IonButton,
} from '@ionic/react';

import './Lobby.css';

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { startVoting } from '../store/main.slice';
import { RootState } from '../app/utilities/types';

import Groups from '../components/Groups';

const Lobby: React.FC = () => {
  const dispatch = useDispatch();

  const {currentUser} = useSelector((state: RootState) => state.authState);

  //! const handleStartVoting = (event: any) => {
  const handleStartVoting = (event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
    //TODO socket.emit('start voting');
    dispatch(startVoting());
  };

  const isAdmin = currentUser!.role === "admin";

  return (
    <section className="" style={{flex: 3, maxWidth: '100vw'}}>
      <div className="flex flex-col vote-area">
        <h2>{isAdmin ? "Partie en attente..." : "Qu'allez-vous voter ?"}</h2>

        <Groups
          showAll={isAdmin}
          groupId={currentUser!.groupId}
          userId={currentUser!.id}
          isAdmin={isAdmin}
        />

        {isAdmin &&
          <IonButton onClick={handleStartVoting} expand="block">
            Allez-y !
          </IonButton>}
      </div>
    </section>
  );
}

export default Lobby;
