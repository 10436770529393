import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { reorderUser } from '../store/users.slice';
import {
  IonReorderGroup,
  ItemReorderEventDetail,
  IonAccordionGroup,
} from '@ionic/react';
import { EntityInfo, EntityInfoProps } from './EntityInfo';

type EntitiesInfoProps = Omit<EntityInfoProps, 'user' | 'key'>;

export const EntitiesInfo: React.FC<EntitiesInfoProps> = (props) => {
  const dispatch = useDispatch();

  function handleReorder(event: CustomEvent<ItemReorderEventDetail>) {
    // The `from` and `to` properties contain the index of the item
    // when the drag started and ended, respectively
    console.log('Dragged from index', event.detail.from, 'to', event.detail.to);
  
    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. This method can also be called directly
    // by the reorder group
    event.detail.complete();
  
    dispatch(reorderUser({userIndex: event.detail.from, newUserIndex: event.detail.to}));
  }
  
  // Remove all group users except the first who is its representative
  // so only show the info for each group once!
  const groupIds = new Set<string>();
  const representativeUsers = props.users.filter(user => {
    if (user.groupId) {
      if (groupIds.has(user.groupId)) return false;
      groupIds.add(user.groupId);
    }
    return true;
  });

  const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null);

  return <IonAccordionGroup className="entities-info" ref={accordionGroup} multiple={true}>

    {/* The reorder gesture is disabled by default, enable it to drag and drop items */}
    {/* TODO WRAP ONLY FOR ADMINS */}
    <IonReorderGroup disabled={props.currentUser!.role !== "admin"} onIonItemReorder={handleReorder}>

      {representativeUsers.map((user) => (
        <EntityInfo key={user.id} user={user} {...props} />
      ))}

    </IonReorderGroup>

  </IonAccordionGroup>
}
