import { useState, useEffect } from 'react';
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import './VoteCountdown.css';

function calcTimeElapsed(startTime: number): number {
    const lastDateNow = Date.now();
    const lastTimeElapsed = Math.round((lastDateNow - startTime) / 1000);
    //! console.log(`lastDateNow=${new Date(lastDateNow).toLocaleTimeString("fr")} lastTimeElapsed=${lastTimeElapsed} startTime=${new Date(startTime).toLocaleTimeString("fr")}`);
    return lastTimeElapsed;
}

interface Props {
  isVoter: boolean,
  hasVotedForAllQuestions: boolean,
  voteeId: string,
  voteStartTime: number,
};

//! export const VoteCountdown: React.FC<Props> = ({
export const VoteCountdown = ({
    isVoter,
    hasVotedForAllQuestions,
    voteeId,
    voteStartTime,
}: Props) => {
//TODO MOVE THESE INTO QUIZ (SERVER SIDE)
const voteEndDuration = 15 * 1 * 100;
//const voteEndDuration = 30 * 1;
const voteWarningDuration = 5 * 1;
    //! const [countdownColours, setCountdownColours] = useState([["#d90368", 0]]);

    const [timeElapsed, setTimeElapsed] = useState(calcTimeElapsed(voteStartTime));

    // This is needed so the countdown timer can be reset or changed, although will countdown fine without it?
    useEffect(() => {
        const timerId = setTimeout(() => {
            const elapsedTime = calcTimeElapsed(voteStartTime);
            setTimeElapsed(elapsedTime);
        }, 1000);
        return () => clearTimeout(timerId);
    });

    useEffect(() => {
        // So countdown doesn't glitch when reset but the above effect timeout hasn't yet been invoked
        const elapsedTime = calcTimeElapsed(voteStartTime);
        //! alert(`Effect: calcTimeElapsed elapsedTime=${elapsedTime} voteStartTime=${voteStartTime}`);
        setTimeElapsed(elapsedTime);
    //? }, [voteeId, quiz]); // ??? SHOULD THIS BE voteStartTime (AS quiz might be mutated)
    }, [voteeId, voteStartTime]); // ??? SHOULD THIS BE voteStartTime (AS quiz might be mutated)

    return (
        <div className={'vote-countdown' + (!hasVotedForAllQuestions && isVoter &&
            timeElapsed >= voteEndDuration - voteWarningDuration && timeElapsed < voteEndDuration ? ' tada-forever' : '')}
        >
            <CountdownCircleTimer onComplete={() => {
                    // do your stuff here
                    //return [true, 1500]; // Repeat animation in 1.5 seconds
//!                 return [!false, 99990];
                    return {
                        shouldRepeat: !false,
                        //delay?: number,
                        newInitialRemainingTime: 99990,
                    };
                }}
                isPlaying
                duration={voteEndDuration}
                initialRemainingTime={voteEndDuration - timeElapsed}
                /* colors={[["#d90368"], ["#d90368", 1 - voteWarningDuration / voteEndDuration], ["#ffd400"]] as any} */
                /* colors={[["#d90368", 0], ["#d90368", .75], ["#ffd400", .751], ["#ffd400", 1]] as any} */
/*              colors={(timeElapsed >= voteEndDuration - voteWarningDuration ? "#ffd400" : "#d90368") as any}*/
/*key={`${quiz.title}--${votee.id}`}*/
key={timeElapsed}
                // colors="#d90368"
                colors="#000000"
                size={140}
                strokeLinecap="square"
                strokeWidth={16}
            >{
                ({ remainingTime, elapsedTime }) => {
                    if (remainingTime! <= 0) {
                    return isVoter && !hasVotedForAllQuestions &&
                        <div className="timer" style={{fontSize: '2.0rem'}}>Trop tard !</div>
                    }

                    if (remainingTime && remainingTime <= voteWarningDuration) {
                    //! setCountdownColours([["#ffd400", 0]]);
                    }

                    return (
                        <div className="timer" style={{fontSize: '2.4rem'}}>
                            {/* <div className="text">Secondes</div> */}
                            <div className="value">{remainingTime}</div>
                            {/* <div className="text">restantes</div> */}
                        </div>
                    );
                }
            }
            </CountdownCircleTimer>
        </div>

        // {false && <>
        // <div>Start Time={voteStartTime}</div>
        // <div>Start Date={new Date(voteStartTime).toLocaleTimeString("fr")}</div>
        // </>}
    );
}
