import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MainState, User } from '../app/utilities/types';

const initialState: MainState = {
  timeElapsed: 0,
  // TODO This is more a 'VoteState'?
  votee: undefined,
  voteeId: undefined,
  stopVoting: false,
  scrollToPos: undefined,
  scrollToDuration: undefined,
};

const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    resetState: (state) => {
      state.votee = undefined;
      state.voteeId = undefined;
      state.stopVoting = false;
      state.scrollToPos = undefined;
      state.scrollToDuration = undefined;
    },
    startVoting: (state) => {
    },
    // Will be dispatched by middleware when votee id set
    setVotee: (state, { payload: votee }: PayloadAction<User | undefined>) => {
      state.votee = votee;
    },
    setVoteeId: (state, { payload }: PayloadAction<string>) => {
      state.voteeId = payload;
      state.stopVoting = false;
    },
    //? Same as setVoteeId but the middleware doesn't intercept this and tell the server to broadcast back to the others (to avoid infinite sends when the server broadcasts back to clients)
    setVoteeIdSilently: (state, { payload: voteeId }: PayloadAction<string>) => {
      //? setVoteeId(voteeId);
      state.voteeId = voteeId;
      state.stopVoting = false;
    },
    nextVotee: (state) => {
      // Middleware will intercept this and do a socket emit
      // TODO HOW TO DO THIS DIRECTLY INSTEAD OF VIA A STORE ACTION???
    },
    setStopVoting: (state, {payload: stopVoting = true}: PayloadAction<boolean | undefined>) => {
      state.stopVoting = stopVoting;
    },
    setStopVotingSilently: (state, {payload: stopVoting = true}: PayloadAction<boolean | undefined>) => {
      //? setStopVoting(stopVoting);
      state.stopVoting = stopVoting;
      state.votee = undefined;
      state.voteeId = undefined;
    },
    stopVotingAndGoBackToPreviousVote: (state, {payload: stopVoting = true}: PayloadAction<boolean | undefined>) => {
      state.stopVoting = stopVoting;
    },
    // TODO any --> HTMLElement
//?    scrollTo: (state, {payload: scrollToRef = undefined}: PayloadAction<any | undefined>) => {
    scrollTo: (state, {payload}: PayloadAction<any>) => {
const scrollToRef = payload.ref;
      //! state.forceScrollTo += 1;
      if (scrollToRef?.current) {
        const rect = {x: 0, y: scrollToRef.current.offsetTop + scrollToRef.current.offsetHeight};
        state.scrollToPos = [rect.x, rect.y];
      } else {
        state.scrollToPos = [0, 0];
      }
      state.scrollToDuration = payload.duration;
      //! state.scrollToRef = scrollToRef;
      console.log(`Main state: scrollTo -> scrollToPos=${JSON.stringify(state.scrollToPos)} scrollToDuration=${state.scrollToDuration}`);
    },
  },
});

function getWindowRelativeOffset(parentWindow: any, elem: any) {
  var offset = {
      left : 0,
      top : 0
  };

  // relative to the target field's document
  offset.left = elem.getBoundingClientRect().left;
  offset.top = elem.getBoundingClientRect().top;

  // now we will calculate according to the current document, this current
  // document might be same as the document of target field or it may be
  // parent of the document of the target field
  var childWindow = elem.document.frames.window;
  while (childWindow != parentWindow) {
      offset.left = offset.left + childWindow.frameElement.getBoundingClientRect().left;
      offset.top = offset.top + childWindow.frameElement.getBoundingClientRect().top;
      childWindow = childWindow.parent;
  }

  return offset;
};

export const {
  resetState,
  startVoting,
  setVotee,
  setVoteeId,
  setVoteeIdSilently,
  nextVotee,
  setStopVoting,
  setStopVotingSilently,
  stopVotingAndGoBackToPreviousVote,
  scrollTo,
} = mainSlice.actions;

export default mainSlice.reducer;

// Action
/*
export function initQuiz(quizNotBegun: boolean) {
  return async (dispatch: Dispatch, getState: () => {}) => {
    //? Are these synchronous???
    //dispatch(getUsers());
    if (quizNotBegun) {
      dispatch(beginQuiz());
    }
    dispatch(getCurrentQuiz());
  };
};
*/

/*
export function initApp() {
  return async (dispatch: Dispatch, getState: () => {}) => {
    //! dispatch(setLoading());
    dispatch(getUsers()).then(async () => {
      // if (users.length === 1) {
      //   dispatch(beginQuiz());
      // }
      // dispatch(getCurrentQuiz());

      try {
        const { data: {voteeId} } = await axios.post('/api/begin-quiz');
        dispatch(mainSlice.actions.setVoteeId(voteeId));
      } catch (e) {
        // Not handling errors
        console.log(e);
      } finally {
        //! dispatch(setLoadingComplete());
      }
    });
  }
};
*/
