//TODO MOVE (SERVER/CLIENT COMMON) TYPES TO COMMON DIR
import {
    User, Group, Answer,
    VotingPolicy,
  } from './types';
//import { log } from './utils';

//TODO COPY OF SAME FILE IN SERVER REPO FOR NOW (USE COMMON SHARED DIR EVENTUALLY)...
export function calcCombinedPoints(answers: Answer[], users: User[], groups: Group[]): {totalPoints: number, numAnswers: number} {
  const groupsPoints = new Map<string, number[]>();

  // Map groups by id => answers (non grouped players will have an empty id grouping)
  console.log('calcCombinedPoints', users, answers);

  answers.filter(answer => !answer.abstained).forEach(answer => {
    const user = users.find(user => user.id === answer.userId);
//? WHY IS THIS CRASHING SOMETIMES??? (user is undefined but why???)
if (!user) return; // TODO THIS SEEMS TO FIX IT BUT FIND OUT WHY AN ANSWER HAS A USERID THAT DOESN'T EXIST IN THE USERS LIST?????!!!!!!!!!
    const groupId = user!.groupId ?? "";

    if (!groupsPoints.has(groupId)) {
      // groupedVotes[groupId] = {numPoints: 0, numAnswers: 0};
      groupsPoints.set(groupId, []);
    }

    const numPoints = answer.choice ?? 0;
    groupsPoints.get(groupId)!.push(numPoints);
  });

  let totalPoints = 0;
  let numAnswers = 0;

  //? Weirdly this wouldn't compile for the client repo? (Possibly because its compile target is es5)
  //? for (let [groupId, groupPoints] of groupsPoints.entries()) {
  groupsPoints.forEach(function (groupPoints, groupId) {
    // Calculate final vote according to group's voting policy
    const group = groups.find(group => group.id === groupId);
    if (group) {
      const {numPoints} = combinePoints(groupPoints, group.votingPolicy, group.votingPolicyParam);
      totalPoints += numPoints;
      ++numAnswers;
    }
  });

  return {totalPoints, numAnswers};
}

// POLICIES
// AVG ALL (n = all)
// AVG TOP n (MODE = n=1)
// AVG FIRST (TO VOTE) n
// SEEDED (DETERMINISTIC) RANDOM CHOICE?
export function combinePoints(votes: number[], votingPolicy: VotingPolicy, votingPolicyParam?: number): {numPoints: number, totalPoints: number} {
  if (votingPolicy === "mode") {
    votes = votes.sort((a, b) => b - a);
  }
  else if (votingPolicy === "invMode") {
    votes = votes.sort((a, b) => a - b);
  }
  else if (votingPolicy === "first") {
    //? votes = votes.sort((a, b) => a.timestamp - b.timestamp);
  }

  let maxAnswers = votingPolicyParam ?? votingPolicy === "mean" ? 0 : 1;
  if (maxAnswers) {
    votes = votes.slice(0, maxAnswers);
  }
  else {
    maxAnswers = votes.length;
  }

  const totalPoints = votes.reduce((totalPoints, numPoints) => totalPoints + numPoints, 0);
  const avgPoints = totalPoints / maxAnswers;

  return {numPoints: avgPoints, totalPoints};
}
