
import {
  IonInput,
  IonItem,
  IonLabel,
  InputCustomEvent,
} from '@ionic/react';

import './TextField.css';

import React, { useRef, useLayoutEffect } from 'react';

export interface Props {
  //! onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChange: (event: InputCustomEvent) => void;
  //! onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  //onBlur: (event: FocusEvent) => void;
  onBlur: (event: any) => void;
  error?: string;
  value: string;
  label: string;
  placeholder: string;
  name: string;
  disabled?: boolean;
  autofocus?: boolean;
  style?: object;
}

export const TextField: React.FC<Props> = ({
  onChange,
  onBlur,
  error,
  name,
  value,
  label,
  placeholder,
  disabled,
  autofocus,
  style,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    // Force the autocomplete attribute to be off after the DOM renders.
    // Necessary as the browser (or React?) seems to reset this?
    inputRef.current?.setAttribute('autocomplete', 'off');
  }, []);

  return (
    <IonItem>
      <IonLabel>{label}</IonLabel>
      <IonInput
        name={name}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        autocomplete="off"
        onIonChange={onChange}
        onIonBlur={onBlur}
        style={style}
        autofocus={autofocus}
      />
      {!!error && <div className="my-2 text-left text-red-400 text-sm">{error}</div>}
    </IonItem>
    );
/*
  return (
    <div className="input-field mb-6">
      <label htmlFor={name} className="block mb-2 text-left text-gray-500 font-semibold">
        {label}
      </label>
      <input
        type="text"
        name={name}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        autoComplete="false"
        className={
          'w-full p-3 placeholder-gray-300 border border-gray-200 rounded-md shadow-md focus:outline-none focus:ring  focus:ring-opacity-50' +
          (!!error ? ' border-red-300 ring-red-300' : '')
        }
        ref={inputRef}
      />
      {!!error && <div className="my-2 text-left text-red-400 text-sm">{error}</div>}
    </div>
  );
*/
}
