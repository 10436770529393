import {
  IonBadge,
  IonChip,
  IonContent,
  IonIcon,
  IonTitle,
  IonToolbar,
  IonModal,
  IonLabel,
  IonCard,
  IonButton,
  IonButtons,
  IonText,
  InputCustomEvent,
} from '@ionic/react';

import { close } from 'ionicons/icons';

//! TEMP SO BUILD/DEPLOY WON'T FAIL
/* eslint-disable */

import './Login.css';

import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { validateTextField } from '../app/utilities/validation';
import { login, setAutoLogin, logout } from '../store/auth.slice';
import { /*getGroups,*/ addGroup, joinGroup, leaveGroup } from '../store/groups.slice';

import { RootState, User, Group, UserQuizHistory } from '../app/utilities/types';
//! import { getUsers } from '../store/users.slice';
import { debug } from '../app/utilities/debug';

import { Header } from '../components/Header';
import { VoteIcon, icons } from '../components/VoteIcons';
import { TextField } from '../components/TextField';
import Groups from '../components/Groups';
import UserAvatar, { Avatar, userAvatars } from '../components/UserAvatar';


/*

import { config, useTransition, animated } from '@react-spring/web';

interface ScoresProps {
  scores: UserQuizHistory[],
  prevScores?: UserQuizHistory[],
  animEffect: 0 | 1, // 0=Tilt 1=Grow
};

function Scores({scores, prevScores, animEffect = 0}: ScoresProps): JSX.Element {
  const rows = useRef<UserQuizHistory[]>(prevScores?.length ? prevScores : scores);
  const [animPhase, setAnimPhase] = useState(0);

  const rowHeight = 70;
  let height = 0;
  let enterNum = 0;
  let leaveNum = 0;
  let numResting = 0;

  const transitions = useTransition(
    rows.current.map((data, index) => ({ ...data, y: (height += rowHeight) - rowHeight, index })),
    animPhase === 0
    ? {
      key: (item: any) => item.user.id,
      from: (item: any) => ({
// ALSO NOT SETTING THE FROM WILL SET IT TO BE THE SAME AS THE TO (for useSpring) - IS SAME HERE FOR ENTER/UPDATE/LEAVE???
        x: 0,
        opacity: 0,
        [animEffect === 0 ? 'rotateX' : 'scaleX']: animEffect === 0 ? 90 : 0,
      }),
      enter: ({ y, index }) => ({
        y,
        opacity: 1,
        [animEffect === 0 ? 'rotateX' : 'scaleX']: animEffect === 0 ? 0 : 1,
        delay: index * 250,
      }),
      update: ({ y }) => ({
        y,
        delay: 1000,
      }),
      config: animEffect === 0 ? config.gentle : config.default,
      onRest: () => {
        // Trigger the 2nd animation phase once all elements come to rest
        ++numResting;
        if (numResting === rows.current.length) {
          setTimeout(() => {
            rows.current = scores;
            setAnimPhase(1);
          }, 5000);
        }
      },
    }
    : {
      key: (item: any) => item.user.id,
      from: {
        x: -300,
        opacity: 0,
        rotateX: 0,
      },
      leave: ({ y }) => ({
        x: 300,
        y,
        opacity: 0,
        delay: leaveNum++ * 300,
      }),
      enter: ({ y }) => ({
        x: 0,
        y,
        opacity: 1,
        rotateX: 0,
        delay: 1000 + enterNum++ * 300,
      }),
      update: ({ y }) => ({
        y,
        delay: 1000,
      }),
      config: config.wobbly,
    },
  );

  return (
    <div className={`score__list anim-effect--${animEffect === 0 ? 'tilt' : 'grow'}`} style={{ height }}>
      {transitions((style, item, t, index) => {
        const iconType = 'star';
        const numVotes = item.avgVotes;
        const numIconsToShow = Math.floor(numVotes ?? 0);

        return (
          <animated.div className="score__card" style={{ zIndex: rows.current.length - index, ...style }}>
            <div className="score__cell">
              <div className="score__details">
                <span className="name">{item.user.name}</span>
                <span className="votes" data-num-votes={numVotes}>
                  <span className="vote-icons">
                      {Array(Math.max(numIconsToShow, 1)).fill(null).map((_, i) => (
                        <span key={`vote-icon-${i}`} style={{color: icons[iconType].colour, opacity: numIconsToShow > 0 ? 1 : .35}}>
                            <VoteIcon iconType={iconType} />
                        </span>
                      ))}
                  </span>
                </span>
              </div>
            </div>
          </animated.div>
        );
      })}
    </div>
  );
}

*/






export interface FormFields {
  userName: string,
  groupName: string,
  groupId?: string,
  avatarId?: string,
};

export interface FormErrors {
  userName?: string,
  groupName?: string,
};

const Login: React.FC = () => {
  const {currentUser} = useSelector((state: RootState) => state.authState);

  const {groups, loading: groupsLoading} = useSelector(
    (state: RootState) => state.groupsState
  );

  const chooseAvatarModal = useRef<HTMLIonModalElement>(null);
  const joinGroupModal = useRef<HTMLIonModalElement>(null);

  const [selectedAvatarId, setSelectedAvatarId] = useState(currentUser?.avatarId);
  const [selectedGroupId, setSelectedGroupId] = useState(currentUser?.groupId);

  const selectedGroup = groups.find(
    group => group.id === (selectedGroupId ?? "")
  ) as Group;

  const {
    roomId,
    name,
    role,
    isPlaying,
  } = useSelector((state: RootState) => state.authState);

  const [user, setUser] = useState<FormFields>({
    userName: name ?? "",
    groupName: "",
    groupId: currentUser?.groupId ?? "",
    avatarId: currentUser?.avatarId,
  });
  const [errors, setErrors] = useState<FormErrors>({});
  const [doBounceEffect, setDoBounceEffect] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  const {users} = useSelector(
    (state: RootState) => state.usersState
  );

//? ALSO IN PRELOGIN, MAYBE REDUNDANT HERE...
/*
  useEffect(() => {
    if (roomId != null) {
      dispatch(getGroups(roomId));
    }
  }, [dispatch, roomId]);
*/

//! NOW SEND FROM THE SERVER AFTER 'JOIN ROOM' SOCKET EVENT
/*
  useEffect(() => {
    console.log("In useEffect 2");
    // This will dispatch setUsers when done, then the socket (store) middleware will initialise the quiz
// TODO INHIBIT THE ABOVE SIDE EFFECT DONE IN SOCKET MIDDLEWARE!!!
    if (roomId != null) {
      dispatch(getUsers(roomId));
    }
  }, [dispatch, roomId]);
*/

  // Add green dot for online users (COPIED FROM Chat.tsx)
/*
  const usersWithOnlineData = useMemo(() => {
    if (users.length < 1) {
      return [];
    }

    return users
      .map((user) => ({
        ...user,
        online: onlineUsersById.some((onlineId) => onlineId === user.id),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [users, onlineUsersById]);
  
  //! const activeUsers = usersWithOnlineData.filter(user => user.online);
*/
  
  useEffect(() => {
    //TODO This should really be done once all CSS bounce-in animations end events have fired
    // (intercept event for each dom element then remove its bounce-in class instead of doing it using a React effect)
    setTimeout(() => { setDoBounceEffect(false); }, 2000);
  }, []);

  const validate = (name: string, value: string) => {
    switch (name) {
      case 'userName':
      case 'groupName':
        setErrors({ ...errors, [name]: validateTextField(value) });
        break;
    }
  };

  const isLoginButtonDisabled = () => {
    return !user.userName || !!errors.userName;
  };

  //! const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const handleChange = (event: InputCustomEvent) => {
    const { name, value } = event.target;

    setUser(user => ({
      ...user,
      [name]: value,
    }));

    if (errors[name as keyof FormErrors]) {
      validate(name, value as string);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    validate(name, value);
  };

  const handleSelectGroup = (event: React.MouseEvent<HTMLIonChipElement, MouseEvent>) => {
    const el = event.currentTarget as any;
    const groupId = el.dataset.groupid;
    setSelectedGroupId(groupId);
  };

  const handleLeaveGroup = (event: React.MouseEvent<HTMLIonIconElement, MouseEvent>) => {
    setUser(user => ({
      ...user,
      groupId: undefined,
    }));

    setSelectedGroupId(undefined);
  };

  const handleJoinOrLeaveGroup = (event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
    joinGroupModal.current?.dismiss();

    setUser(user => ({
      ...user,
      groupId: selectedGroupId,
    }));

    if (selectedGroupId) {
      dispatch(joinGroup(selectedGroupId));
    } else {
      dispatch(leaveGroup());
    }
  };

  function dismissJoinGroup() {
    setUser(user => ({
      ...user,
      groupId: undefined,
    }));
    setSelectedGroupId(undefined);
    joinGroupModal.current?.dismiss();
  }

  const [userAvatarsArr] = useState<[string, Avatar][]>(
    Object.entries(userAvatars).filter(([id, avatar]) => id !== 'DEFAULT')
  );

  const [userAvatarTags, setUserAvatarTags] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const userAvatarTags = userAvatarsArr.reduce(
      (tags, [id, avatar]) => {
        avatar.tags
          .filter(tag => Object.keys(selectedGroup.avatarTags).includes(tag))
          .forEach(tag => tags[tag] = selectedGroup.avatarTags[tag]);
        return tags;
      },
      {} as Record<string, boolean>,
    );
    setUserAvatarTags(userAvatarTags);
  }, [selectedGroupId]);

  const activeUserAvatars = userAvatarsArr.filter(
    //! ([id, avatar]) => avatar.tags.some(tag => userAvatarTags[tag])
    ([id, avatar]) => Object.entries(userAvatarTags).every(
      ([tag, active]) => !active || avatar.tags.includes(tag)
    )
  );
console.log(`userAvatarTags=${JSON.stringify(userAvatarTags)}`);
  const handleSelectAvatar = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const el = event.currentTarget as any;
    const avatarId = el.dataset.avatarid;
    setSelectedAvatarId(avatarId);
  };

  const handleConfirmAvatar = (event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
    chooseAvatarModal.current?.dismiss();

    setUser(user => ({
      ...user,
      avatarId: selectedAvatarId,
    }));
  };

  function dismissChooseAvatar() {
    chooseAvatarModal.current?.dismiss();
  }

  const handleSelectUserAvatarTag = (event: React.MouseEvent<HTMLIonChipElement, MouseEvent>) => {
    const el = event.currentTarget as any;
    const avatarId = el.dataset.avatartag;
    setUserAvatarTags(userAvatarTags => (
      {...userAvatarTags, [avatarId]: true}
    ));
  };

  const handleUnselectUserAvatarTag = (event: React.MouseEvent<HTMLIonIconElement, MouseEvent>) => {
    const el = event.target as any;
    event.stopPropagation();
    const avatarId = el.dataset.avatartag;
    setUserAvatarTags(userAvatarTags => (
      {...userAvatarTags, [avatarId]: false}
    ));
  };

  const handleGroupChange = (event: InputCustomEvent) => {
    const { name, value } = event.target;

    setUser(user => ({
      ...user,
      [name]: value,
    }));

    // if (errors[name as keyof FormErrors]) {
    //   validate(name, value)
    // }
  };

  const handleCreateGroup = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    // TODO SEND NEW GROUP TO SERVER WHICH WILL THEN NOTIFY ALL OTHER CLIENTS
    // TODO JUST DO LOCALLY LIKE THIS FOR NOW
    const groupId = `${user.groupName}--${Math.floor(Math.random() * 99999)}`;

    // TODO USE DEFAULT GROUP (ID="") SETTINGS???
    const newGroup: Group = {
      id: groupId,
      name: user.groupName,
      maxSize: 10,
      votingPolicy: "mean",
      avatarTags: {},
    };

    dispatch(addGroup(newGroup));

    setUser(user => ({
      ...user,
      groupId,
      // Will clear input field, note group name no longer needed (just the id)
      //! groupName: '',
    }));
  };

  const handleGroupBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    //? const { name, value } = event.target
    // validate(name, value)
    //! TEMP TEST
    setErrors({ ...errors, groupName: "Erreur bidan : Ce groupe existe déjà !" });
  };

  //! const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
  const handleSubmit = (event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
    //! event.preventDefault();

    const groupId = user.groupId !== "" ? user.groupId : undefined;

    const loginUser: User = {
      id: "", // This will be emitted by the server in response to the login message
      roomId,
      groupId,
      name: user.userName,
      role,
      isPlaying,
      avatarId: user.avatarId,
    };

//! TEMP!!! SHOULD BY PASS LOGIN AND GO STRAIGHT BACK INTO GAME IF THIS EXISTS (UNLESS GAME NO LONGER EXISTS / STALE SESSION???)
    const oldLoggedInUserString = localStorage.getItem('user');
  debug('HOW TO DEAL WITH LOCALSTORAGE USER LOGIN DETAILS BEING STALE??? oldLoggedInUserString='+oldLoggedInUserString);
    if (oldLoggedInUserString) {
      const __loggedInUser__ = JSON.parse(oldLoggedInUserString);
      loginUser.id = __loggedInUser__.id;
    }

    dispatch(login(loginUser));

    // Instead of logging in user via actual persistent session, save in localStorage
//    localStorage.setItem('user', JSON.stringify(loginUser))

    history.replace('/vote');
  };

  const handleLogout = (event: any) => {
    // Log out session
    localStorage.removeItem('user');

    dispatch(logout());
  };

// TODO THESE WERE MOVED FROM PRELOGIN
/*
  //! const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const handleAdminIsPlayingChange = ({detail: {checked}}: {detail: {checked: boolean}}) => {
    //! const { name, checked } = event.target;
    const name = "isPlaying";

    setLoginInfo(loginInfo => ({
      ...loginInfo,
      [name]: checked,
    }));
  };

  const handleIsPlayingChange = ({detail: {checked}}: {detail: {checked: boolean}}) => {
    setLoginInfo(loginInfo => ({
      ...loginInfo,
      isPlaying: !checked,
    }));
  };

  //! const handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  const handleRoleChange = ({detail: {value}}: {detail: {value: string}}) => {
    //! const { value } = event.target;

    setLoginInfo(loginInfo => ({
      ...loginInfo,
      role: value as Role,
      // TODO TEMP isPlaying: value !== "admin",
      isPlaying: true,
    }));
  };
*/

  const quizHistory: UserQuizHistory[] = [
    {user: {id: "3c", name: "ÉTUDIANT DEUX"}, totalVotes: 20, maxTotalVotes: 22, avgVotes: 22 / 22 * 5},
    {user: {id: "5d", name: "ÉTUDIANT QUATRE"}, totalVotes: 24, maxTotalVotes: 25, avgVotes: 24 / 25 * 5},
    {user: {id: "1a", name: "ADMIN"}, totalVotes: 11, maxTotalVotes: 20, avgVotes: 11 / 20 * 5},
    {user: {id: "4d", name: "ÉTUDIANT 3"}, totalVotes: 8, maxTotalVotes: 25, avgVotes: 8 / 25 * 5},
    {user: {id: "7f", name: "ÉTUDIANT SIX"}, totalVotes: 4, maxTotalVotes: 11, avgVotes: 4 / 11 * 5},
    {user: {id: "2b", name: "ÉTUDIANT UN"}, totalVotes: 3, maxTotalVotes: 11, avgVotes: 3 / 25 * 5},
    {user: {id: "6e", name: "ÉTUDIANT CINQ"}, totalVotes: 1, maxTotalVotes: 10, avgVotes: 1 / 10 * 5},
  ];

  const prevQuizHistory: UserQuizHistory[] = [
    {user: {id: "1a", name: "ADMIN"}, totalVotes: 22, maxTotalVotes: 25, avgVotes: 22 / 25 * 5},
    {user: {id: "2b", name: "ÉTUDIANT UN"}, totalVotes: 12, maxTotalVotes: 25, avgVotes: 12 / 25 * 5},
    {user: {id: "3c", name: "ÉTUDIANT DEUX"}, totalVotes: 15, maxTotalVotes: 15, avgVotes: 15 / 15 * 5},
    {user: {id: "4d", name: "ÉTUDIANT 3"}, totalVotes: 8, maxTotalVotes: 25, avgVotes: 8 / 25 * 5},
    {user: {id: "6e", name: "ÉTUDIANT CINQ"}, totalVotes: 3, maxTotalVotes: 10, avgVotes: 3 / 10 * 5},
    {user: {id: "7f", name: "ÉTUDIANT SIX"}, totalVotes: 1, maxTotalVotes: 11, avgVotes: 1 / 11 * 5},
    {user: {id: "5d", name: "ÉTUDIANT QUATRE"}, totalVotes: 2, maxTotalVotes: 25, avgVotes: 2 / 25 * 5},
  ];

  const maxNumScores = 5;

  const scores = quizHistory.slice(0, maxNumScores);

  const prevScores = prevQuizHistory.slice(0, maxNumScores).map(userVotes => (
    // Copy new matching history items to the old so that the text doesn't snap
    // change when the list is switched during transition animations
    quizHistory.find(item => item.user.id === userVotes.user.id) ?? userVotes
  ));

  const allUsersPlaying = users.filter(user => user.isPlaying);

  return (
<>
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      margin: "0 auto",
      width: "10%",
      minWidth: 400,
      height: "100%",
      textAlign: "center",
    }}>
      <IonCard style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        // margin: "0 auto",
        // width: "10%",
        // minWidth: 800,
      }}>
          {/* <div className="container mx-auto"> */}
            {/* <Scores scores={scores} prevScores={prevScores} animEffect={1} /> */}

            <TextField
              label=""
              name="userName"
              value={user.userName}
              placeholder="Pseudonyme"
              autofocus
              onChange={handleChange}
              onBlur={handleBlur}
              // error={errors.userName}
              style={{
                margin: "15px 0",
                fontSize: "2.3rem",
                textAlign: "center",
                background: "#eee",
                color: "#333",
              }}
            />

{false && <>
            <TextField
              label="Pour créer un nouveau groupe"
              name="groupName"
              value={user.groupName}
              placeholder="Les Schtroumpfs de Ouf"
              disabled={!!user.groupId}
              onChange={handleGroupChange}
              onBlur={handleGroupBlur}
              error={errors.groupName}
            />

            {!user.groupId && user.groupName &&
              <button
                className="px-3 py-1 mb-2 text-white bg-blue font-medium rounded-md shadow-md hover:bg-blue-dark disabled:opacity-50 focus:outline-none"
                onClick={handleCreateGroup}
              >
                Créer ce groupe
              </button>
            }
</>}

{/* // TODO THESE WERE MOVED FROM PRELOGIN */}
        {/* <IonList>
          {false && <IonRadioGroup value={loginInfo.role} onIonChange={handleRoleChange}>
            <IonItem>
              <IonLabel>Administrateur</IonLabel>
              <IonRadio slot="start" value="admin" />
            </IonItem>

            <IonItem>
              <IonLabel>Étudiant</IonLabel>
              <IonRadio slot="start" value="user" />
            </IonItem>
          </IonRadioGroup>}

          <IonItemDivider />

          {loginInfo.role === "admin" && <IonItem>
            <IonToggle name="isAdminPlaying" checked={loginInfo.isPlaying} onIonChange={handleAdminIsPlayingChange} slot="end" />
            Participez au jeu
          </IonItem>}

          {loginInfo.role !== "admin" && <IonItem>
            <IonToggle name="isPlaying" checked={!loginInfo.isPlaying} onIonChange={handleIsPlayingChange} slot="end" />
            Spectateur seulement
          </IonItem>}
        </IonList>

        <IonItemDivider /> */}

            <div style={{display: "flex", justifyContent: "space-between", margin: "15px 15px 0"}}>
              <IonButton color="light" id="open-choose-avatar" expand="block" style={{flex: 1, marginRight: 20}}>
                Choisir un avatar
              </IonButton>

              <IonButton color="light" id="open-join-group" expand="block" style={{flex: 1}}>
                {selectedGroupId ? "Changer de groupe" : "Rejoindre un groupe"}
              </IonButton>
            </div>

            <IonButton disabled={isLoginButtonDisabled()} onClick={handleSubmit} expand="block" style={{margin: 15}}>
              Valider
            </IonButton>
          {/* </div> */}
      </IonCard>
    </div>

    <IonModal id="example-modal" ref={chooseAvatarModal} trigger="open-choose-avatar" backdropDismiss={false}>
      <IonContent>
        <IonToolbar>
          <IonTitle>Choisir votre avatar</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={dismissChooseAvatar}>Fermer</IonButton>
          </IonButtons>
        </IonToolbar>

        <div className="container mx-auto">
          <div className="groups">
            {Object.entries(userAvatarTags).map(([tag, active], i) => {
              return (
                <IonChip
                  key={tag}
                  style={{animationDelay: `${i * 0.25}s`}}
                  color={selectedGroup.avatarTags[tag] ? "primary" : (active ? "secondary" : "dark")}
                  onClick={handleSelectUserAvatarTag}
                  data-avatartag={tag}
                >
                  <IonLabel>
                    {tag}
                    {active && !selectedGroup.avatarTags[tag] &&
                      <IonIcon
                        icon={close}
                        onClick={handleUnselectUserAvatarTag}
                        data-avatartag={tag}
                        style={{marginLeft: 5}}
                      />}
                    </IonLabel>
                </IonChip>
              )
            })}

            {selectedAvatarId &&
              <div style={{display: "block", width: "100%", textAlign: "center"}}>
                <IonButton fill="solid" expand="block" style={{margin: "10px 0"}} onClick={handleConfirmAvatar}>
                  {selectedAvatarId !== user.avatarId ? "Séléctionner cet avatar" : "Rester avec cet avatar"}
                </IonButton>
              </div>
            }

            {!selectedAvatarId &&
              <div style={{display: "block", width: "100%", textAlign: "center", margin: "20px 0"}}>
                <IonText color="medium">Choisir un avatar.</IonText>
              </div>
            }

            {activeUserAvatars.map(([id, avatar], i) => {
              // const groupUsers = allUsersPlaying.filter(user => user.groupId === group.id);
              return (
                <UserAvatar
                  key={id}
                  avatarId={id}
                  name={avatar.name}
                  hasFocus={id === selectedAvatarId}
                  onClick={handleSelectAvatar}
                  style={{cursor: "pointer"}}
                />
              );
            })}
          </div>
        </div>
      </IonContent>
    </IonModal>

    <IonModal id="example-modal" ref={joinGroupModal} trigger="open-join-group" backdropDismiss={false}>
      <IonContent>
        <IonToolbar>
          <IonTitle>Choisir un groupe</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={dismissJoinGroup}>
              {/* {selectedGroupId && selectedGroupId !== user.groupId ? "Quitter le groupe" : "Annuler"} */}
              {user.groupId ? "Quitter le groupe" : "Fermer"}
            </IonButton>
          </IonButtons>
        </IonToolbar>

        <div className="container mx-auto">
          {groupsLoading && <div>En cours de charger les groupes...</div>}
          {!groupsLoading &&
            <div className="groups">
              {groups.filter(({id}) => id !== '').map((group, i) => {
                const groupUsers = allUsersPlaying.filter(user => user.groupId === group.id);
                return (
                  <IonChip
                    key={group.id}
                    // className={`${doBounceEffect ? 'bounce-in' : ''} ${group.id === user.groupId ? 'selected' : ''}`}
                    style={{
                      animationDelay: `${i * 0.25}s`,
                      border: group.id === user.groupId ? "1px dashed currentColor" : "none",
                    }}
                    color={group.id === selectedGroupId ? "primary" : "dark"}
                    onClick={handleSelectGroup}
                    data-groupid={group.id}
                  >
                    <IonLabel>
                      {group.name}
                    </IonLabel>
                    {groupUsers.length !== 0 &&
                      <IonLabel>
                        &nbsp;
                        <IonBadge slot="end" color={group.id === selectedGroupId ? "primary" : "light"}>
                          {groupUsers.length}
                        </IonBadge>
                      </IonLabel>}
                    {/* {group.id === user.groupId && <IonIcon icon={close} onClick={handleLeaveGroup} />} */}
                  </IonChip>
                );
              })}
            </div>
          }
        </div>

        {selectedGroupId &&
          <div style={{display: "block", width: "100%", textAlign: "center"}}>
            <IonButton fill="solid" expand="block" style={{margin: "10px 0"}} onClick={handleJoinOrLeaveGroup}>
              {selectedGroupId !== user.groupId ? "Rejoindre ce groupe" : "Rester dans ce groupe"}
            </IonButton>
          </div>
        }

        {selectedGroupId
          ? <Groups showAll={false} groupId={selectedGroupId} userId={currentUser?.id} />
          : <div style={{display: "block", width: "100%", textAlign: "center", margin: "20px 0"}}>
              <IonText color="medium">Aucun groupe séléctionné en ce moment.</IonText>
            </div>
        }
      </IonContent>
    </IonModal>
</>
  )
};

export default Login;
