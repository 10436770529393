import { io, Socket } from 'socket.io-client';

import { WEB_SOCKET_HOST } from '../utilities/config';

// An API for the stateful socket connection, to keep it out of the global
// namespace and away from the socket middleware
export default class SocketClient {
  socket: Socket | null = null;

  connect() {
    //? HS THIS SEEMS TO FIX THE USER DUPLICATION BUG WHEN THE SERVER GETS STOPPED THEN RESTARTED...
    this.disconnect();

    this.socket = io(WEB_SOCKET_HOST);
    //? this.socket = io(WEB_SOCKET_HOST, {'forceNew': true});
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  isConnected(): boolean {
    return this.socket !== null;
  }

  emit(eventName: string, data: any) {
    if (this.socket) {
      this.socket.emit(eventName, data);
    }
  }

  on(eventName: string, func: () => void) {
    console.log(`SocketClient::on: eventName=${eventName}`);
    if (this.socket) {
      this.socket.on(eventName, func);
    }
  }
}
