import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//! import axios from 'axios';

import { User, UsersState } from '../app/utilities/types';
//! import { setQuiz } from '../store/quiz.slice';

const initialState: UsersState = {
  users: [],
//!  onlineUsersById: [],
  loading: false,
  error: null,
  typingUsers: [],
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // setOnlineUsersById: (state, { payload }: PayloadAction<string[]>) => {
    //   state.onlineUsersById = payload
    // },
    setUsers: (state, { payload }: PayloadAction<{roomId: string, users: User[]}>) => {
//!      state.users = payload.users;
state.users = [...payload.users];
      console.log(`setUsers: state.users=${JSON.stringify(state.users)}`);
    },
    addUser: (state, { payload }: PayloadAction<{user: User, userIndex: number}>) => {
      const {user, userIndex} = payload;
      // If the user index is greater than the number of users then it is a new
      // user and not the reconnection/relogin of an existing user (whose
      // session would have been changed)
      if (userIndex > state.users.length) {
        state.users.push(user);
      }
      else {
        state.users[userIndex] = user;
      }
    },
    deactivateUser: (state, { payload: userId }: PayloadAction<string>) => {
      // const userIndex = state.users.findIndex(user => user.name === userId); //! TEMP user.name
      // state.users.splice(userIndex, 1);
      // Just flag user as not being online for now (so temporarily disconnected users could be somehow reconnected with their last session user if this is remembered???)
      const user = state.users.find(user => user.id === userId);
      if (user) { // TODO THIS CRASHES SOMETIMES WITHOUT THIS CHECK - FIND OUT WHY!!!
        user.online = false;
      }
    },
    removeUser: (state, {payload: userId}: PayloadAction<string>) => {
      //! state.users = state.users.filter(user => user.id !== userId);
      // Users can never be removed as this would invalidate the vote history
      // that references these, so they are just marked as removed.
      const user = state.users.find(user => user.id === userId);
      if (user) {
        user.removed = true;
      }
    },
    reorderUser: (state, { payload }: PayloadAction<{userIndex: number, newUserIndex: number}>) => {
    },
    reorderUserSilently: (state, {payload: {userIndex, newUserIndex}}: PayloadAction<{userIndex: number, newUserIndex: number}>) => {
      //console.log(`reorderUserSilently: userIndex=${userIndex} newUserIndex=${newUserIndex}`);

      // TODO DUPLICATED FROM SERVER CODE: LOGIC TO BE COPIED CLIENT SIDE TOO (WILL NEED TO DISPATCH A 'reorderUserSilently' FROM CLIENTSIDE SOCKET LISTENER SO CLIENTS CAN UPDATE THEIR USER LISTS WITHOUT REEMITTING 'reorderUser')
      const user = state.users[userIndex];
      state.users[userIndex] = {id: "DELETE_ME!"} as User; // TODO NASTY!
      const newUserIndex2 = newUserIndex + (newUserIndex > userIndex ? 1 : 0);
      state.users.splice(newUserIndex2, 0, user);
      state.users = state.users.filter(user => user.id !== "DELETE_ME!")
    },
    setLoading: (state) => {
      state.loading = true;
    },
    setLoadingComplete: (state) => {
      state.loading = false;
    },
    setTypingUser: (state,{payload}) => {
      state.typingUsers = [payload,...[...state.typingUsers].filter(name => name !== payload)];
    },
    removeTypingUser: (state, { payload }) => {
      state.typingUsers = state.typingUsers.filter(name => name !== payload);
    },
    sendThisUserIsTyping: (state, { payload }: PayloadAction<any>) => {
    },
    sendThisUserStoppedTyping: (state, { payload }: PayloadAction<any>) => {
    },
  },
});

export const {
  setUsers,
  addUser,
  deactivateUser,
  removeUser,
  reorderUser,
  reorderUserSilently,
//!  setOnlineUsersById,
  setLoading,
  setLoadingComplete,
  setTypingUser,
  removeTypingUser,
  sendThisUserIsTyping,
  sendThisUserStoppedTyping,
} = usersSlice.actions;

export default usersSlice.reducer;

// Action
//! NOW SEND FROM THE SERVER AFTER 'JOIN ROOM' SOCKET EVENT
/*
export function getUsers(roomId: string) {
  return async (dispatch: Dispatch, getState: () => {}) => {
    dispatch(setLoading());

    try {
      const { data } = await axios(`/api/${roomId}/users`);
console.log('getUsers: data=', data);
      dispatch(setUsers({roomId, users: data.users}));
    } catch (e) {
      // Not handling errors
      console.log(e);
    } finally {
      dispatch(setLoadingComplete());
    }
  };
};
*/

/*
export function nextVotee() {
  return async (dispatch: Dispatch, getState: () => {}) => {
    //! dispatch(setLoading());

    try {
      const { data: {quiz, voteeId} } = await axios.post('/api/next-votee');
console.log('nextVotee: quiz=', quiz, 'voteeId=', voteeId);
//TODO
// if (quiz !== null) {
//   dispatch(setQuiz(quiz));
// }
      dispatch(setVoteeId(voteeId));
      dispatch(setQuiz(quiz));
    } catch (e) {
      // Not handling errors
      console.log(e);
    } finally {
      //! dispatch(setLoadingComplete());
    }
  };
};
*/
