//! TEMP SO BUILD/DEPLOY WON'T FAIL
/* eslint-disable */


import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Particles from '../app/utilities/particles';
import { VoteIcon, inlineSvg, getIcon } from './VoteIcons';
import UserAvatar from './UserAvatar';

import { RootState, User, Quiz, Question } from '../app/utilities/types';

interface Props {
  quiz: Quiz,
  users: User[],
}

export const Votometer: React.FC<Props> = ({
  quiz,
  users,
}) => {
  const { voteeId } = useSelector(
    (state: RootState) => state.mainState
  );
  const { voteHistory } = useSelector(
    (state: RootState) => state.quizState
  );


//! COPIED AND ADAPTED FROM Votes.tsx COMPONENT

const countVotes = (question: Question, questionIndex: number, _voteeId?: string): any => {
  if (_voteeId !== voteeId) {
    // If not votee user who's currently receiving votes then use vote history
    const voteeHistory = voteHistory.find(history => history.voteeId === _voteeId);
    const quizzesHistory = voteeHistory?.quizzesHistory;

    if (!quizzesHistory) {
      let totalVotes = 0;
      let numVotes = 0;
      let maxVotes = 0;
      return {totalVotes, numVotes, maxVotes};
    }

    const quizIndex = quizzesHistory.length - 1; // TODO CHECK IF THIS IS OK FOR THOSE WHO HAVEN'T BEEN VOTED FOR YET
    question = {
      answers: quizzesHistory[quizIndex].questionsHistory[questionIndex].answers,
      // Plugin from current 'live' question structure as needed below
      maxVotes: question.maxVotes,
    } as Question;
  }

  // Check if user has abstained from voting
/*
  if (question.answers.some(answer => answer.userId === userId && answer.abstained)) {
//!        return null;
return {totalVotes: 0, numVotes : 0};
  }
*/

//COUNT MAX VOTES
let maxVotes = 0;

const numVoters = users
  .filter(user => user.isPlaying && user.id !== _voteeId)
  .reduce((numVoters, user) => {
    const abstained = question.answers.some(answer => answer.userId === user.id && answer.abstained);
    return numVoters + (abstained ? 0 : 1);
  }, 0);

maxVotes += question.maxVotes * numVoters;

//console.log(`Filtered question.answers for votee ${_voteeId}`,question.answers);

// .filter(answer => answer.userId === userId && !answer.abstained));

  const {total: totalVotes, num: numVotes} = question.answers
    //? .filter(answer => answer.userId !== _voteeId && !answer.abstained)
    .filter(answer => !answer.abstained)
    .reduce((votes, answer) =>
      ({total: votes.total + (answer.choice ?? 0), num: votes.num + 1}), {total: 0, num: 0});

//console.log('totalVotes',totalVotes,'numVotes',numVotes);
  return {totalVotes, numVotes, maxVotes};
};

  //! START
function calcTotalVotes(_voteeId?: string) {


let totalVotes = 0;
let maxTotalVotes = 0;
// TODO THE PROBLEM HERE IS THAT THE ANSWERS FOR THESE QUESTIONS ARE THOSE FOR THE CURRENTLY ACTIVE VOTEE!!!
// TODO SO ALL PLAYERS SEE THE SAME COUNTS (OF THIS ACTIVE VOTEE)
// TODO USE HISTORY OF QUESTIONS/ANSWERS (SEND TO CLIENT IF ADMIN?) AND USE THE REQD voteeId QUESTIONS BASED ON THIS
quiz.questions.forEach((question, i) => {
  const {totalVotes: totalVotesForQuestion, maxVotes: maxVotesForQuestion} = countVotes(question, i, _voteeId);
//  console.log('totalVotesForQuestion',totalVotesForQuestion,'numVotesForQuestion',numVotesForQuestion);
  totalVotes += totalVotesForQuestion;
  maxTotalVotes += maxVotesForQuestion;
});
console.log('totalVotes', totalVotes, 'maxTotalVotes', maxTotalVotes);


//! END
return {totalVotes, maxTotalVotes};
}

function calcVotometerHeight(userId: string) {
  const {totalVotes, maxTotalVotes} = calcTotalVotes(userId);

  const votometerBarMaxHeight = 552;
  const votometerBarHeight = votometerBarMaxHeight * (totalVotes / maxTotalVotes);
  const votometerBarTop = 133 + votometerBarMaxHeight - votometerBarHeight;

  return {
    totalVotes,
    maxTotalVotes,
    votometerBarMaxHeight,
    votometerBarHeight,
    votometerBarTop,
  }
}

  // if (totalVotes === maxTotalVotes) {
  //   Particles.confetti(document.body);
  // }

  console.log(`url('data:image/svg+xml;charset=UTF-8, ${inlineSvg({iconType: "heart", colour: 'red', size: 16})}');`);

  const focusUserId = ""; // TODO MAKE USER CURRENTLY RECEIVING VOTES?

  return (
<div style={{display: "flex", position: "relative", top: 250, height: "40vh", justifyContent: "space-around", alignItems: "flex-end"}}>
    {/* <img src="/assets/images/votometer.png" alt="Votometer" /> */}
{users.filter(user => user.isPlaying).map((user, i) => {
  const {
    totalVotes,
    maxTotalVotes,
    votometerBarMaxHeight,
    votometerBarHeight,
    votometerBarTop,
  } = calcVotometerHeight(user.id);
  return (
      <UserAvatar
        key={user.id}
        avatarId={user.avatarId}
        name={user.name}
        hasFocus={user.id === focusUserId}
      >


    <div className="xflex-1">
      <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-around", alignItems: "flex-start", flex: 1}}>
      </div>
      {/* <div style={{position: 'absolute', top: votometerBarTop, left: '32%', width: '16px', height: votometerBarHeight,
          background: 'transparent',
          transition: 'all .5s cubic-bezier(0.24, -0.31, 0.84, 1.35) 0s',
          backgroundImage: `url('data:image/svg+xml;charset=UTF-8,${inlineSvg({iconType: "heart", colour: 'red', size: 16})}')`
        }}
      /> */}
      <div style={{top: votometerBarTop, height: votometerBarHeight,
          transition: 'all .5s cubic-bezier(0.24, -0.31, 0.84, 1.35) 0s',
        }}>
        {quiz.questions.map((question, i) => {
          const {totalVotes: totalVotesForQuestion, maxVotes: maxVotesForQuestion} = countVotes(question, i, user.id);
          // TODO DRY
          const votometerSegmentHeight = votometerBarMaxHeight * (totalVotesForQuestion / maxTotalVotes);
          const icon = getIcon(question.iconType);
          const colour = "%23" + (icon.saturatedColour ?? icon.fadedColour).substring(1); // Url encode # symbol
          return (
            <div style={{left: '31.5%', width: '16px', height: votometerSegmentHeight,
                background: icon.colour,
                transition: 'all .5s cubic-bezier(0.24, -0.31, 0.84, 1.35) 0s',
                backgroundImage: `url('data:image/svg+xml;charset=UTF-8,${inlineSvg({iconType: question.iconType, colour, size: 16})}')`,
                backgroundPosition: 'bottom',
                boxShadow: '0px -1px 3px #222',
              }}
            />
          );
        })}
      </div>
    </div>


{false &&
<div className="Xflex-1" style={{position: 'relative', minWidth: '150px'}}>

{/* TEMP!!! */}
{/* TODO DRY */}
<div className="bar">
{quiz.questions.map((question, i) => {
  const {totalVotes: totalVotesForQuestion, maxVotes: maxVotesForQuestion} = countVotes(question, i, user.id);
  return (
    <div className="segment" style={{'--percent': totalVotesForQuestion / maxTotalVotes * 100, '--fill-color': getIcon(question.iconType).colour} as any}>
      <div className="fill">
        {/* <span style={{zIndex: 100}}>{totalVotesForQuestion / maxVotesForQuestion * 100}<sub> %</sub></span> */}
      </div>
    </div>
  );
})}

  <div className="base">
    <div className="text">{maxTotalVotes / totalVotes * 100}<sub> %</sub></div>
    <div className="icon"><i className="fa-duotone fa-chart-pie-simple"></i></div>
  </div>
</div>

</div>
}


</UserAvatar>)
})}

</div>
  );
};
