const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const WEB_SOCKET_HOST = isLocalhost
    ? 'http://localhost:5000'
//!    : 'http://188.166.16.9:5000';
//!    : 'http://classvote.cocorifrench.com:5000';
    : 'https://classvote.app:5000';

//export const WEB_SOCKET_HOST = 'http://188.166.16.9:5000';
//export const WEB_SOCKET_HOST = 'http://localhost:5000';
//export const WEB_SOCKET_HOST = 'http://172.18.0.2:5000';

// Exposing the local client & server via tunnel services:
// Use this instead to connect to the local server exposed via the Localtunnel `lt --port 5000` command (replace url with each newly generated one)
// Ref: https://localtunnel.github.io/www/
// For the client itself, use ngrok `ngrok http 3000 --host-header=rewrite` as the server CORS policy has been set up to allow requests from these subdomains
// Ref: https://ngrok.com/docs
// Also, for why `--host-header=rewrite` is needed:
// https://stackoverflow.com/questions/45425721/invalid-host-header-when-ngrok-tries-to-connect-to-react-dev-server
// export const WEB_SOCKET_HOST = 'https://heavy-catfish-32.loca.lt';

// export const WEB_SOCKET_HOST = 'https://spotty-puma-52.loca.lt';