//! TEMP SO BUILD/DEPLOY WON'T FAIL
/* eslint-disable */

import { Dispatch } from 'redux';

import { addMessage } from '../../store/messages.slice';
import {
  /*getUsers,*/ setUsers, addUser, removeUser, removeTypingUser, /*setOnlineUsersById,*/ setTypingUser,
} from '../../store/users.slice';
import { setGroups } from '../../store/groups.slice';
import { preLogin, login, setLoggedInUserId } from '../../store/auth.slice';

import {
  //! initQuiz, 
  setAnswer, removeUserAnswers,
  //!
  /*setLoading, setLoadingComplete,*/ setQuiz,
} from '../../store/quiz.slice';
import { User, Group, Quiz, Message, RootState } from '../utilities/types';

import { initSocketListeners } from './socketListeners';

//! import axios from 'axios';
import { debug } from '../utilities/debug';

interface SocketMiddlewareParams {
  dispatch: Dispatch,
  getState: () => RootState,
};

export default function socketMiddleware(socket: any) {
  return (params: SocketMiddlewareParams) => (next: any) => async (action: any) => {
    const { dispatch, getState } = params;
    const { type, payload } = action;

    switch (type) {
      /*
      case 'main/initApp': {
        // dispatch(getUsers());
        getUsers();

        //const { data: {voteeId} } = await axios.post('/api/begin-quiz');
        //dispatch(mainSlice.actions.setVoteeId(voteeId));
        break;
      }
      */

      // Connect to the socket when a user logs in
      case 'auth/preLogin': {
        //! alert(`auth/preLogin: payload=${JSON.stringify(payload)}`);
        debug(`auth/preLogin: payload=${JSON.stringify(payload)}`);

        // Do not reconnect if already connected - this can happen when
        // navigating back to the initial login page, e.g. when the back
        // button is pressed.
//??? THIS IS POO EVEN IF IT WORKS, REFACTOR...
if (socket.isConnected()) {
  socket.disconnect();
//???
setTimeout(() => {
  socket.connect();
  initSocketListeners(socket, payload, dispatch, getState);
}, 1000); //! GIVE ENOUGH TIME TO DISCONNECT
}
else // ... ???

{
        if (!socket.isConnected()) {
          socket.connect();
}

// TODO INIT SOCKET LISTENERS WITH THE ABOVE socket.connect FUNCTION (THEN CAN PUT THE socket.removeAllListeners INSIDE THE SocketClient disconnect FN)
// TODO WATCH OUT FOR THE CLOSURE INSIDE initSocketListeners THOUGHT THAT socket.on('user connected'... USES THE PAYLOAD PASSED IN AFTER A SERVER HANDSHAKE
          initSocketListeners(socket, payload, dispatch, getState);

//? TRY WAITING FOR SERVER TO RESPOND TO CONNECTION (PROBABLY NOT NECESSARY)
//TODO REMOVE IF NOT NECESSARY!
        }
        break;
      }

      // Connect to the socket when a user logs in
      case 'auth/login': {
//!        socket.connect();

        // Set up all the socket event handlers
        // When these events are received from the socket, they'll dispatch the proper Redux action

        // Update the online users list every time a user logs in or out
//!        socket.on('users online', (onlineUsers: string[]) => {
//!          dispatch(setOnlineUsersById(onlineUsers));
//!        });

        // Add the current user to the online users list
        socket.emit('new login', payload);
        break;
      }

      // Disconnect from the socket when a user logs out
      case 'auth/logout': {
        socket.disconnect();

        // Need to do this here as this socket will not receive the subsequent
        // 'remove user' broadcast response from the server after disconnection
        const currentUser = getState().authState.currentUser;
        if (currentUser?.id) { //! TEMP UNTIL PRELOGIN PROPERLY IMPLEMENTED!
          dispatch(removeUser(currentUser!.id));
        }
        break;
      }

      // Disconnect from the socket when a user logs out
      case 'auth/fakeDisconnect': {
        debug('auth/fakeDisconnect');
        socket.disconnect();
        break;
      }

      case 'main/startVoting': {
        socket.emit('start voting');
        break;
      }

      case 'main/setVoteeId': {
        //? const {userId, voteeId} = payload;

        // Send message to server if the answer was from this local client user
//!        const currentUser = getState().authState.currentUser;
//!        if (userId === currentUser!.id) {
//TODO CAN THE CLIENT BROADCAST DIRECTLY WITHOUT NEEDING SERVER TO RELAY THIS MESSAGE???
          socket.emit('set votee id', payload);
//!        }
        break;
      }

      case 'main/setStopVoting': {
        socket.emit('stop voting');
        break;
      }

      case 'main/stopVotingAndGoBackToPreviousVote': {
        socket.emit('stop voting and go back to previous vote');
        break;
      }

      /*
      case 'main/nextVotee': {
        const { data: { quiz, voteeId } } = payload;
        console.log('voteeId=', voteeId, 'quiz=', quiz);
        dispatch(setQuiz(quiz));
        dispatch(setVoteeId(voteeId));
      }
      */
/*
//! NOW DONE IN auth/preLogin
      case 'users/setUsers': {
        const {roomId, users} = payload;
        console.log(`in users/setUser users=${users}`);
        debug(`in users/setUser users=${users}`);
        //! dispatch(getCurrentQuiz());
        //getCurrentQuiz();
//!        const quizNotBegun = users.length === 1;

//!        dispatch(initQuiz(quizNotBegun));
//...
//!        console.log('initQuiz: quizNotBegun=', quizNotBegun);

        dispatch(setLoading());

        try {
          // if (quizNotBegun) {
          //   // const { data: { voteeId } } = await axios.post('/api/begin-quiz');
          //   // dispatch(setVoteeId(voteeId));
          //   await axios.post('/api/begin-quiz');
          // }

          // TODO HOW TO CALL getCurrentQuiz() ACTION INSIDE OF THIS ACTION?
          // dispatch(getCurrentQuiz());
          const { data: { quiz, voteeId } } = await axios(`/api/${roomId}/quiz`);
          console.log('voteeId=', voteeId, 'quiz=', quiz);
          dispatch(setQuiz(quiz));
          dispatch(setVoteeId(voteeId));
      
      //? dispatch(mainSlice.actions.setVoteeId(voteeId));
        } catch (e) {
          // Not handling errors
          console.log(e);
        } finally {
          dispatch(setLoadingComplete());
        }
      
        break;
      }
*/
      // Telling the server that this user is typing
      case 'users/sendThisUserIsTyping': {
        socket.emit('typing...', payload);
        break;
      }

      // Telling the server that this user stopped typing
      case 'users/sendThisUserStoppedTyping': {
        socket.emit('stopped typing...', payload);
        return;
      }

      case 'users/removeUser': {
        // Remove all user's answers
        const userId = payload;
        //const users = getState().usersState.users;
        //const userIndex = users.findIndex(user => user.name === userId); //! TEMP user.name
        dispatch(removeUserAnswers(userId));
        break;
      }

      case 'users/reorderUser': {
        const {userIndex, newUserIndex} = payload;
        console.log(`SOCKET MIDDLEWARE users/reorderUser reorderUser userIndex=${userIndex} newUserIndex=${newUserIndex}`);
        socket.emit('reorder user', payload);
        break;
      }

      // Let the server be the source of truth for all messages; don't dispatch anything
      case 'messages/sendMessage': {
        socket.emit('send message', payload);
// WHY RETURN & NOT BREAK (so next() isn't called)
        return;
      }
/*
      case 'groups/addGroup': {
        console.log(`groups/addGroup: payload=${JSON.stringify(payload)}`);
        socket.emit('add group', payload);
        break;
      }

      case 'groups/joinGroup': {
        socket.emit('join group', payload);
        break;
      }

      case 'groups/leaveGroup': {
        socket.emit('leave group', payload);
        break;
      }
*/
      case 'quiz/resetQuizStartTime': {
        socket.emit('reset quiz start time');
        break;
      }

      // Let the server be the source of truth for all messages; don't dispatch anything
      case 'quiz/setAnswer': {
        const {questionId, userId, choice} = payload;

        // Send message to server if the answer was from this local client user
        const currentUser = getState().authState.currentUser;
        if (userId === currentUser!.id) {
          // const quiz = getState().quizState.quiz;
          // socket.emit('set answer', {quizId: quiz!.id, questionId, userId, choice});
          socket.emit('set answer', {questionId, userId, choice});
        }
        break;
      }

      case 'quiz/removeAnswer': {
        const {questionId, userId, abstained} = payload;

        // Send message to server if the answer was from this local client user
        const currentUser = getState().authState.currentUser;
        if (userId === currentUser!.id) {
          // const quiz = getState().quizState.quiz;
          // socket.emit('remove answer', {quizId: quiz!.id, questionId, userId, abstained});
          socket.emit('remove answer', {questionId, userId, abstained});
        }
        break;
      }
    }

    return next(action);
  };
};
