import party from 'party-js';
import { getIcon, icons } from '../../components/VoteIcons';
import { IconType } from './types';

//! type BuiltinShapeName = "square" | "rectangle" | "circle" | "roundedSquare" | "roundedRectangle" | "star";

export function emitIcons(emissionSource: HTMLElement, iconType: IconType, size: number) {
    // Make sure the icon shapes exists
    Object.entries(icons).forEach(([name, icon]) => {
        if (!party.resolvableShapes[name]) {
          party.resolvableShapes[name] = `<svg viewBox="0 0 16 16" height="32" width="32">
            <path d="${icon.svgPath}" />
          </svg>`;
        }
    });
  
    party.scene.current.createEmitter({
        emitterOptions: {
            loops: 1,
            useGravity: false,
            modules: [
                new party.ModuleBuilder()
                    .drive("size")
                    //.by((t: number) => 0.5 + 0.3 * (Math.cos(t * 10) + 1))
                    .by((t: number) => (size / 3) + (size / 4 - size / 3) * (Math.cos((t) * 10) + 1))
                    .build(),
                new party.ModuleBuilder()
                    .drive("opacity")
                    .by((t: any) => t)
                    .through("size")
                    .build(),              
                new party.ModuleBuilder()
                    .drive("rotation")
                    .by((t: any) => new party.Vector(0, 0, 100).scale(t))
                    .relative()
                    .build(),
            ],
        },
        emissionOptions: {
            rate: 0,
            //bursts: [{ time: 0, count: party.variation.skew(20, 10) }],
            bursts: [{ time: 0, count: party.variation.skew(size * 3, size * 4) }],
            sourceSampler: party.sources.dynamicSource(emissionSource),
            angle: party.variation.range(0, 360),
            initialSpeed: 400,
            initialColor: party.variation.gradientSample(
                party.Gradient.simple(
                    party.Color.fromHex(getIcon(iconType).colour),
                    party.Color.fromHex(getIcon(iconType).fadedColour)
                )
            ),
        },
        rendererOptions: {
            // Available built-in shapes: square, rectangle, circle, roundedSquare, roundedRectangle, star
            shapeFactory: iconType,
            applyLighting: undefined,
        },
    });
}

const Particles = {
    emitIcons,
    confetti: party.confetti,
};

export default Particles;
