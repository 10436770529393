import './LeaderBoard.css';
//? TODO NEEDED FOR VOTE ICON STYLES
//import '../components/Votes.css';

import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState, UserQuizHistory, User } from '../app/utilities/types';
import { VoteIcon, getIcon } from '../components/VoteIcons';
import { EntityInfo } from '../components/EntityInfo';

import { scrollTo } from '../store/main.slice';


import { config, useTransition, animated } from '@react-spring/web';

interface ScoresProps {
  scores: UserQuizHistory[],
  prevScores?: UserQuizHistory[],
  animEffect: 0 | 1, // 0=Tilt 1=Grow
};

function Scores({scores, prevScores = [], animEffect = 0}: ScoresProps): JSX.Element {


// -------------------------------------------------------------
// STUFF NEEDED FOR EntityInfo
const { currentUser } = useSelector((state: RootState) => state.authState);

const { users, typingUsers } = useSelector(
  (state: RootState) => state.usersState
);

const { groups } = useSelector(
  (state: RootState) => state.groupsState
);

const { quiz } = useSelector(
  (state: RootState) => state.quizState
);
// -------------------------------------------------------------



  const rows = useRef<UserQuizHistory[]>(prevScores.length ? prevScores : scores);
  const [animPhase, setAnimPhase] = useState(0);

  const rowHeight = 70;
  let height = 0;
  let enterNum = 0;
  let leaveNum = 0;
  let numResting = 0;

  const transitions = useTransition(
    rows.current.map((data, index) => ({ ...data, y: (height += rowHeight) - rowHeight, index })),
    animPhase === 0
    ? {
      key: (item: any) => item.user.id,
      from: {
        x: 0,
        opacity: 0,
        [animEffect === 0 ? 'rotateX' : 'scaleX']: animEffect === 0 ? 90 : 0,
      },
      enter: ({ y, index }) => ({
        y,
        opacity: 1,
        [animEffect === 0 ? 'rotateX' : 'scaleX']: animEffect === 0 ? 0 : 1,
        delay: index * 250,
      }),
      update: ({ y }) => ({
        y,
        delay: 1000,
      }),
      config: animEffect === 0 ? config.gentle : config.default,
      onRest: () => {
        // Trigger the 2nd animation phase once all elements come to rest
        ++numResting;
        if (numResting === rows.current.length) {
          setTimeout(() => {
            rows.current = scores;
            setAnimPhase(1);
          }, 2000);
        }
      },
    }
    : {
      key: (item: any) => item.user.id,
      from: {
        x: -300,
        opacity: 0,
        // rotateY gives a nice horizonal expansion (try with elastic rebound!)
        rotateX: 0,
      },
      leave: ({ y }) => ({
        x: 300,
        y,
        opacity: 0,
        delay: leaveNum++ * 300,
      }),
      enter: ({ y }) => ({
        x: 0,
        y,
        opacity: 1,
        rotateX: 0,
        delay: 1000 + enterNum++ * 300,
      }),
      update: ({ y }) => ({
        y,
        delay: 1000,
      }),
      config: config.wobbly,
    },
  );

  return (
    <div className={`score__list anim-effect--${animEffect === 0 ? 'tilt' : 'grow'}`} style={{ height }}>
      {transitions((style, item, t, index) => {
        const iconType = 'star';
        const numVotes = item.avgVotes;

        const numIconsToShow = Math.ceil(numVotes ?? 0);

        return (
          <animated.div className="score__card" style={{ zIndex: rows.current.length - index, ...style }}>
            <div className="score__cell">
              <div className="score__details">
                <EntityInfo
                  key={item.user.id}
                  user={users.find(user => user.id === item.user.id) as User}
                  groups={groups}
                  users={users}
                  quiz={quiz}
                  currentUser={currentUser}
                  typingUsers={typingUsers}
                  infoContext="leaderboard"
                />
                <span className="votes" data-num-votes={numVotes}>

                  <span className="vote-icons
                    rr--group rr--dir-x rr--has-stroke rr--space-sm
                    rr--has-border rr--fx-zoom rr--fx-colors rr--rx-lg rr--pointer">

                    {Array(Math.max(numIconsToShow, 1)).fill(null).map((_, i) => {
                      const fillPercent = (i === numIconsToShow - 1
                        ? (numVotes ?? 0) - (numIconsToShow - 1)
                        //? numIconsToShow - (numVotes ?? 0)
                        : (i >= (numVotes ?? 0) ? 0 : 1)) * 100;

                        return (
                          <span className="vote-icon"
                                key={i}
                                data-rating-index={i}
                                style={{color: getIcon(iconType).colour, opacity: numIconsToShow > 0 ? 1 : .35}}
                          >
                              <VoteIcon id={`item-${index}__vote-icon-${i}`}
                                        iconType={iconType}
                                        fillPercent={fillPercent}
                                        // TODO rename isActive or something?
                                        outline={fillPercent !== 0}
                              />
                          </span>
                        );
                    })}

                  </span>
                </span>
              </div>
            </div>
          </animated.div>
        );
      })}
    </div>
  );
}





const LeaderBoard: React.FC = () => {
  const history = useHistory();
  const { quizDone, allQuizzesDone, quizHistory, prevQuizHistory, quizzesHistory } = useSelector(
    (state: RootState) => state.quizState
  );

  const dispatch = useDispatch();

  const { currentUser } = useSelector((state: RootState) => state.authState);

  useEffect(() => {
    //? dispatch(scrollTo()); // TODO WHY DOESN'T COMPILE?
    dispatch(scrollTo([0, 200]));
  }, []);

  useEffect(() => {
    // quizDone will be set to false once the server sends a 'new quiz started' message
    //! alert(`LeaderBoard useEffect: quizDone=${quizDone} allQuizzesDone=${allQuizzesDone}`);
    if (!quizDone && !allQuizzesDone) {
      history.replace('/vote');
    }
  }, [quizDone, allQuizzesDone, history]);

  if (allQuizzesDone) {
    const maxNumScores = 5;
    const globalScores = quizzesHistory.slice(0, maxNumScores);
    // const globalScores = quizzesHistory.slice(0, maxNumScores).map(userVotes => ({
    //   ...userVotes,
    //   user: {
    //     ...userVotes.user,
    //     // Needs a prefix otherwise the keys are the same as the scores list
    //     // and won't cause a UI update
    //     id: `global-score-${userVotes.user.id}`,
    //   },
    // }));

    return (
      <section className="Xcontainer bg-white text-center rounded-md shadow-lg" style={{flex: 3, maxWidth: '100vw'}}>
        <div className="flex flex-col vote-area">
          <Scores scores={globalScores} animEffect={1} />
        </div>
      </section>
    );
  }

  const maxNumScores = currentUser!.role === "admin" ? Infinity : 5;
  const scores = quizHistory.slice(0, maxNumScores);
  const prevScores = prevQuizHistory.slice(0, maxNumScores).map(userVotes => (
    // Copy new matching history items to the old so that the text doesn't snap
    // change when the list is switched during transition animations
    quizHistory.find(item => item.user.id === userVotes.user.id) ?? userVotes
  ));

  return (
    <section className="Xcontainer bg-white text-center rounded-md shadow-lg" style={{flex: 3, maxWidth: '100vw'}}>
      <div className="flex flex-col vote-area">
        <Scores scores={scores} prevScores={prevScores} animEffect={prevScores.length ? 0 : 1} />
      </div>
    </section>
  );
};

export default LeaderBoard;
