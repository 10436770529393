import './Groups.css';

import React from 'react';
import { useSelector } from 'react-redux';

import { RootState, User, Group } from '../app/utilities/types';

import Users from '../components/Users';

interface GroupsProps {
  showAll?: boolean,
  groupId?: string,
  userId?: string,
  isAdmin?: boolean,
}

const Groups: React.FC<GroupsProps> = ({showAll = true, groupId, userId, isAdmin}) => {
  const {users} = useSelector((state: RootState) => state.usersState);

  // TODO TEMP
  // useEffect(() => {
  //   console.log(`LOGIN useEffect: users=${JSON.stringify(users)}, users.length=${JSON.stringify(users.length)}`);
  // }, [users]);

  const {groups} = useSelector(
    (state: RootState) => state.groupsState
  );

  // Add green dot for online users
/*
  const usersWithOnlineData = useMemo(() => {
    if (users.length < 1) {
      return [];
    }

    return users
      .map((user) => ({
        ...user,
        online: onlineUsersById.some((onlineId) => onlineId === user.id),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [users, onlineUsersById]);
*/

  //! const activeUsers = usersWithOnlineData.filter(user => user.online);
  //! if (!activeUsers.length) return <div>No active users! users={JSON.stringify(users)} activeUsers={JSON.stringify(activeUsers)}</div>;

  const allUsersPlaying = users.filter(user => user.isPlaying);

    //! TEST
if (!true)
[
  {name: "Harvey Stroud 1", groupId: "HP01", avatarId: "HP_1"},
  {name: "Harvey Stroud 2", groupId: "HP01", avatarId: "EMOJI_2"},
  {name: "Harvey Stroud 3", groupId: "HP01", avatarId: "HP_1"},
  {name: "Harvey Stroud 4", groupId: "HP01", avatarId: "HP_1"},
  {name: "Harvey Stroud 4a", groupId: "HP01", avatarId: "HP_3"},
  {name: "Harvey Stroud 4b", groupId: "HP01", avatarId: "HP_2"},
  {name: "Thierry Maligne", groupId: "HP01", avatarId: "HP_5"},

  {name: "Harvey Stroud 1x", groupId: "HP01", avatarId: "HP_1"},
  {name: "Harvey Stroud 2x", groupId: "HP01", avatarId: "HP_1"},
  {name: "Harvey Stroud 3x", groupId: "HP01", avatarId: "HP_1"},
  {name: "Harvey Stroud 4x", groupId: "HP01", avatarId: "EMOJI_1"},
  {name: "Harvey Stroud 4ax", groupId: "HP01", avatarId: "HP_3"},
  {name: "Harvey Stroud 4bx", groupId: "HP01", avatarId: "HP_2"},
  {name: "Thierry Malignex", groupId: "HP01", avatarId: "HP_5"},

  {name: "Harvey Stroud 5", groupId: "HP02", avatarId: "HP_1"},
  {name: "Harvey Stroud 6", groupId: "HP02", avatarId: "HP_1"},
  {name: "Harvey Stroud 7", groupId: "HP04", avatarId: "HP_1"},
  {name: "Harvey Stroud 8", groupId: "HP03", avatarId: "HP_1"},

  {name: "Kissing heart un", groupId: "GRP_A", avatarId: "EMOJI_1"},
  {name: "Smiley cat one", groupId: "GRP_A", avatarId: "EMOJI_2"},
  {name: "Kissing heart deux", groupId: "GRP_B", avatarId: "EMOJI_1"},
  {name: "Smiley cat deux", groupId: "GRP_B", avatarId: "EMOJI_2"},
  {name: "Smiley cat trois", groupId: "GRP_B", avatarId: "EMOJI_2"},

  {name: "Kissing heart quatre", groupId: "GRP_C", avatarId: "EMOJI_1"},

  {name: "Hans Solo", groupId: undefined, avatarId: "HP_6"},
].forEach((user, i) =>
    allUsersPlaying.push({
      ...user,
      id: String(user.name),
      role: "user",
      isPlaying: true,
    })
);

  //! const soloUsers = allUsersPlaying.filter(user => !user.groupId);
  const populatedGroups = groups.filter(group => allUsersPlaying.some(user => user.groupId === group.id));

  // TODO THIS COULD ALSO BE SHARED BY THE EntitiesInfo COMPONENT
  // Remove all group users except the first who is its representative
  // so only show the info for each group once!
  // Note solo users are ^placed into a default group.
  const groupsMembersByIndex = new Map<number, User[]>();
  const representativeUsers = allUsersPlaying
    // Ensure most recent users appear first
    .reverse()
    .filter(user => {
      // Only include group and members or solo user if not an admin
      if (!showAll && (user.groupId !== groupId || (!user.groupId && user.id !== userId))) {
        return false;
      }

      // Ensures default group will be found when the group id is not defined
      const userGroupId = user.groupId ?? "";
      const groupIndex = groups.findIndex(group => group.id === userGroupId);
      const members = groupsMembersByIndex.get(groupIndex);
      if (!members) {
        groupsMembersByIndex.set(groupIndex, [user]);
        return true;
      }
      members.push(user);
      return false;
    });

  const groupsMembers: [Group, User[]][] = Array.from(groupsMembersByIndex)
    .sort(([group1Index], [group2Index]) => {
      // Ensure default group with index 0 will be positioned last
      return (group1Index || Infinity) - (group2Index || Infinity);
    })
    .map(([groupIndex, users]) => [groups[groupIndex], users]);

  // TODO IF GROUP HAS NO MEMBER IT WILL NOT APPEAR IN THIS LIST (AND SO ITS IMAGE WILL NOT BE DISPLAYED!)
  //! console.log(`groupsMembers=${JSON.stringify(groupsMembers)}`);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        // alignItems: "center",
        flexWrap: "wrap",
      }}>
      {groupsMembers.map(([group, members]) => (
        <div
          key={group.id}
          style={{
            display: "flex",
            // flexDirection: "row",
            flexDirection: "column",
            justifyContent: group.id ? "flex-start" : "center",
            alignItems: "center",
            flex: 1,
            // maxWidth: 600,
            minWidth: 300,
            border: "4px dotted #aaa",
            borderRadius: 20,
            margin: 5,
            padding: 5,
        }}>

          {/* <div style={{display: "flex", justifyContent: "center", width: "100%", height: "max-content", flex: "1 1"}}> */}
{(group.id || groupsMembers.length > 1) && <>
          <div
            style={{
display: "flex",
justifyContent: "center",
alignItems: "center",
              flex: 1,
              width: "100%",
              height: "auto",
              minWidth: 100,
              minHeight: 100,
              maxWidth: isAdmin ? 220 : 220,
              // maxHeight: isAdmin ? 250 : 300,
              maxHeight: isAdmin ? 250 : 300,
              // border: "1px dashed green",
              margin: 5,
            }}>
            {group.id &&
              <img
                src={group.image ?? "/assets/images/groups/default.jpg"}
                alt={`Groupe ${group.name}`}
                className="bounce-in"
                style={{
//flex: 0,
                  // margin: 5,
                  borderRadius: 16,
                  // minWidth: 100,
                  // minHeight: 100,
                  // maxWidth: 250,
                  // width: 250,
                  // height: 250,
                  width: "100%",
                  height: "100%",
                  // flex: "0 1",
                  // border: "1px dashed red",
                }}
              />
            }
            {!group.id && groupsMembers.length > 1 && <>
              <div style={{textTransform: "uppercase", fontWeight: "bold", fontSize: "1.5rem", margin: 10}}>Individus</div>
            </>}
          </div>

          {group.id &&
            <div style={{fontWeight: "bold", fontSize: "1.6rem", margin: 10}}>
              {group.id ? group.name : "Individus"}
            </div>}
</>}
{/* <div style={{flex: "1 1 0%"}}> */}
          <Users users={members} focusUserId={userId} />
{/* </div> */}
        </div>
      ))}
    </div>
  );
}

export default Groups;
