import { User, Group, Quiz, InfoContext } from '../app/utilities/types';
import { UserInfo } from './UserInfo';
import { GroupInfo } from './GroupInfo';

export interface EntityInfoProps {
  key: string,
  user: User,
  groups: Group[],
  users: User[],
  quiz: Quiz | null,
  currentUser: User | null,
  voteeId?: string,
  typingUsers: string[],
  infoContext: InfoContext,
}

export const EntityInfo: React.FC<EntityInfoProps> = ({ user, users, groups, quiz, currentUser, voteeId, typingUsers, infoContext }) => {
  // if (!user) {
  //   console.log("EntityInfo: user null/undefined!!!!!!");
  //   return null;
  // }
  if (user.groupId) {
    const group = groups.find(group => user.groupId === group.id) as Group;
    return (
      <GroupInfo
        className={`group-info entity-info info-context-${infoContext}`}
        key={group.id}
        group={group}
        users={users}
        quiz={quiz}
        currentUser={currentUser}
        voteeId={voteeId}
        typingUsers={typingUsers}
        infoContext={infoContext}
      />
    );
  }

  return (
    <UserInfo
      className={`user-info entity-info info-context-${infoContext}`}
      key={user.id}
      user={user}
      quiz={quiz}
      currentUser={currentUser}
      voteeId={voteeId}
      typingUsers={typingUsers}
      infoContext={infoContext}
    />
  );
};
