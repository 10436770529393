//! import axios from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Group, GroupsState } from '../app/utilities/types';

const initialState: GroupsState = {
  groups: [],
  loading: false,
  error: null,
};

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setGroups: (state, { payload }: PayloadAction<Group[]>) => {
      state.groups = payload;
      console.log(`setGroups: payload=${JSON.stringify(payload)}`);
    },
    addGroup: (state, { payload }: PayloadAction<Group>) => {
      console.log(`addGroup: payload=${JSON.stringify(payload)}`);
      state.groups.push(payload);
    },
    joinGroup: (state, { payload }: PayloadAction<string>) => {
    },
    leaveGroup: (state) => {
    },
    setLoading: (state) => {
      state.loading = true;
    },
    setLoadingComplete: (state) => {
      state.loading = false;
    },
  },
})

export const {
  setGroups,
  addGroup,
  joinGroup,
  leaveGroup,
  setLoading,
  setLoadingComplete,
} = groupsSlice.actions;

export default groupsSlice.reducer;

// Action
//! NOW SEND FROM THE SERVER AFTER 'JOIN ROOM' SOCKET EVENT
/*
export function getGroups(roomId: string) {
  debug(`Ìn getGroups: roomId=${roomId}`);
  return async (dispatch: Dispatch, getState: () => {}) => {
    dispatch(setLoading());
    try {
      debug(`getGroups: calling endpoint '/api/${roomId}/groups'`);
      const { data } = await axios(`/api/${roomId}/groups`);
      dispatch(setGroups(data.groups));
    } catch (e) {
      // Not handling errors
      console.log(e);
    } finally {
      dispatch(setLoadingComplete());
    }
  }
}
*/
