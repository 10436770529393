import {
  IonBadge,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonRadioGroup,
  IonListHeader,
  IonLabel,
  IonItem,
  IonRadio,
  IonToggle,
  IonItemDivider,
  IonCard,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';

//! TEMP SO BUILD/DEPLOY WON'T FAIL
/* eslint-disable */

import './Vote.css';
import Lobby from '../pages/Lobby';

import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';

import { VoteArea } from '../components/VoteArea';
import { startVoting } from '../store/main.slice';
import { sendMessage, getMessages } from '../store/messages.slice';
import { sendThisUserIsTyping, sendThisUserStoppedTyping } from '../store/users.slice';
import { logout } from '../store/auth.slice';
//! import { getUsers } from '../store/users.slice';
//! import { initQuiz } from '../store/quiz.slice';
import { RootState, Message } from '../app/utilities/types';

const Vote: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [messageInput, setMessageInput] = useState('');

  const { currentUser, roomId } = useSelector((state: RootState) => state.authState);

  const { users, loading: usersLoading, /*onlineUsersById,*/ typingUsers } = useSelector(
    (state: RootState) => state.usersState
  );

  const { groups, loading: groupsLoading } = useSelector(
    (state: RootState) => state.groupsState
  );

  const { quiz, loading: quizLoading, quizDone, allQuizzesDone } = useSelector(
    (state: RootState) => state.quizState
  );

  const { messages, loading: messagesLoading } = useSelector(
    (state: RootState) => state.messagesState
  );

  const handleLogout = (event: any) => {
    // Log out session
// TODO TEMP DO NOT REMOVE THIS FROM LOCAL SESSION IMMEDIATELY SO CAN TEST USER BEING ABLE TO LOG BACK IN AND RESUME THEIR PREVIOUS USER (WITH SESSION ID RENEWED)
    //? localStorage.removeItem('user');

    dispatch(logout());
  };

  useEffect(() => {
    if (quizDone || allQuizzesDone) {
      history.replace('/scores');
    }
  }, [quizDone, allQuizzesDone]);

  // Debounce the typing indication emit
  // const debouncedTypingIndicationEmit = useCallback(
  //   debounce(() => dispatch(sendThisUserIsTyping(currentUser!.name)), 500),
  //   [], // Will be created only once initially
  // );
  const debouncedTypingIndicationEmit = useCallback(
    () => debounce(() => dispatch(sendThisUserIsTyping(currentUser!.name)), 500),
    [currentUser], // Will be created only once initially
  );

  //! const handleStartVoting = (event: any) => {
  const handleStartVoting = (event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
    //TODO socket.emit('start voting');
    dispatch(startVoting());
  };

  const handleSubmitForm = (event: any) => {
    event.preventDefault();

    if (messageInput && messageInput.trim() !== '') {
      const message: Message = {
        content: messageInput.trim(),
        date: dayjs().format(),
        author: currentUser!.name,
      };

      dispatch(sendThisUserStoppedTyping(currentUser!.name));
      dispatch(sendMessage(message));
    }

    setMessageInput('');
  };

  // To remove typing indicator when messageInput changes to empty string
  useEffect(() => {
    if (currentUser) {
      if (messageInput === '') {
        dispatch(sendThisUserStoppedTyping(currentUser.name));

        // Handles the last debounced emit
        setTimeout(() => {
          dispatch(sendThisUserStoppedTyping(currentUser.name));
        }, 500);
      }
    }
  }, [messageInput, currentUser]);

  const handleChangeInput = (event: any) => {
    if (event.target.value !== '') debouncedTypingIndicationEmit();

    setMessageInput(event.target.value);
  }

//! NOW SEND FROM THE SERVER AFTER 'JOIN ROOM' SOCKET EVENT
/*
  useEffect(() => {
    console.log("In useEffect 2");
    if (roomId != null) {
      // This will dispatch setUsers when done, then the socket (store) middleware will initialise the quiz
      dispatch(getUsers(roomId));
      // dispatch(getCurrentQuiz());
      dispatch(getMessages(roomId));
    }
  }, [dispatch, roomId]);
*/

/*
  useEffect(() => {
    console.log("In useEffect 3");
//TODO THIS CHECK FILTERS OUT THE INVOCATIONS BEFORE getUsers() HAS BEEN CALLED
//? if (users?.length) {
//!      if (users.length === 1) {
        const quizNotBegun = users.length === 1
//TODO FIND OUT WHY THIS USER DOESN'T IMMEDIATELY APPEAR IN THE USERS LIST WHEN THIS EFFECT HOOK FIRES FOR PLAYERS AFTER THE FIRST ONE??
;//????????          && users.find(user => user.id === currentUser!.id) !== null;
//        if (quizNotBegun) {
          dispatch(initQuiz(quizNotBegun));
//        }
//        dispatch(getCurrentQuiz());
//!      }
      //dispatch(getCurrentQuiz());
//? }
  //! }, [users, dispatch]);
  }, [dispatch]);
*/

  // Add green dot for online users
/*
  const usersWithOnlineData = useMemo(() => {
    if (users.length < 1) {
      return [];
    }

    return users
      .map((user) => ({
        ...user,
        online: onlineUsersById.some((onlineId) => onlineId === user.id),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [users, onlineUsersById]);
*/

  // Add green dot for online users
  const reversedMessages = useMemo(() => {
    if (messages.length < 1) {
      return [];
    }

    return [...messages].reverse();
  }, [messages]);

  // TODO WHY IS quizLoading NOT true INITIALLY (useEffect ORDER ABOVE?) SO NEED quiz.length === 0 CHECK AS WELL ???
/*
  if (messagesLoading || usersLoading || groupsLoading || (quizLoading || quiz === null)) {
    return <div>Veuillez patienter...</div>;
  }
*/

  if (!users.length) return <div>No users yet!!!</div>;
  if (!currentUser) return <div>No current user yet!!!</div>;

  //! const activeUsers = usersWithOnlineData.filter(user => user.online);
  //! if (!activeUsers.length) return <div>No active users! users={JSON.stringify(users)} activeUsers={JSON.stringify(activeUsers)}</div>;

  return (
    <>
      {/* <section className="Xcontainer bg-white text-center rounded-md shadow-lg" style={{flex: 1}}>
        {/x* users={usersWithOnlineData} *x/}
        {currentUser.role === "admin" ?
          <AdminSidebar
            quiz={quiz}
            users={users}
            groups={groups}
            currentUser={currentUser}
            typingUsers={typingUsers}
            infoContext="sidebar"
          />
          :
          <Sidebar
            quiz={quiz}
            users={users}
            groups={groups}
            currentUser={currentUser}
            typingUsers={typingUsers}
            infoContext="sidebar"
          />
        }
      </section> */}
      <section className="Xbg-white Xtext-center Xrounded-md Xshadow-lg" style={{flex: 3, maxWidth: '100vw'}}>
        {/* users={activeUsers} */}
        {quiz
          ? <VoteArea
              quiz={quiz}
              users={users}
              groups={groups}
              typingUsers={typingUsers}
            />
          : <Lobby />
          // : <div className="flex flex-col vote-area">
          //     {currentUser!.role === "admin"
          //       ? <>
          //         <h2>Partie en attente...</h2>

          //         {currentUser?.role === "admin" &&
          //           <AdminSidebar
          //             quiz={quiz}
          //             users={users}
          //             groups={groups}
          //             currentUser={currentUser}
          //             // voteeId={voteeId}
          //             typingUsers={typingUsers}
          //             infoContext="lobby"
          //           />
          //         }

          //         <IonButton onClick={handleStartVoting} expand="block">
          //           Allez-y !
          //         </IonButton>
          //       </>
          //       : <h2>Qu'allez-vous voter ?</h2>
          //     }
          //   </div>
        }
      </section>
      {/*
      <section className="Xcontainer bg-white text-center rounded-md shadow-lg" style={{flex: 1}}>
        <ChatArea
          messages={reversedMessages}
          messageInput={messageInput}
          handleSubmitForm={handleSubmitForm}
          handleChangeInput={handleChangeInput}
        />
      </section>
      */}
    </>
  );
}

export default Vote;
