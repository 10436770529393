export const validateRoomCode = (roomCode: string) => {
  const len = roomCode.trim().length;
  if (len === 0) {
    return "Le code est requis";
  }
  // TODO TEMP
  if (true === !false) return;

  if (len < 5) {
    return "Le code est incomplet";
  }
  if (len > 5) {
    return "Le code est invalide";
  }
};

export const validateEmail = (email: string) => {
  if (email.trim() === '') {
    return 'Email is required'
  }

  if (!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
    return "L'adresse e-mail n'est pas valide";
  }
};

export const validateTextField = (value: string) => {
  if (value.trim() === '') {
    return "Le nom de l'utilisateur est requis";
  }

  // TODO TEMP
  if (true === !false) return;

  if (/[^. A-Za-z0-9_-]/.test(value)) {
    return "Le nom de l'utilisateur ne doit contenir que des lettres, des chiffres, tirets, espaces, points au underscores";
  }
};
