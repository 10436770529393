import { User, Group, Quiz, InfoContext } from '../app/utilities/types';
import { EntitiesInfo } from './EntitiesInfo';
//! import { UsersInfo } from './UsersInfo';
//! import { GroupsInfo } from './GroupsInfo';

interface SidebarProps {
  users: User[],
  groups: Group[],
  quiz: Quiz | null,
  currentUser: User | null,
  voteeId?: string,
  typingUsers: string[],
  infoContext: InfoContext,
}

//! type Optional<T, K extends keyof T> = Partial<Pick<T, K>> & Omit<T, K>;
//! type UsersProps = Omit<SidebarProps, 'groups'>;
//! type UserInfoProps = Omit<Optional<SidebarProps, 'groups'> & {user: User}, 'users'>;
//! type GroupsProps = SidebarProps;

/*
const userVotes = (user: User, question: Question) => {
  //! const userAnswers = question.answers.filter(answer => answer.userId === user.id);
  //! return userAnswers.reduce((totalVotes, answer) => totalVotes += answer.choice, 0) / (userAnswers.length || 1);
  const answer = question.answers.find(answer => answer.userId === user.id);
  return answer?.choice ?? null;
};
*/

export const Sidebar: React.FC<SidebarProps> = ({ users, groups, quiz, currentUser, voteeId, typingUsers, infoContext }) => {
  const allUsersPlaying = users.filter(user => user.isPlaying);
  //! const soloUsers = allUsersPlaying.filter(user => !user.groupId);
  const populatedGroups = groups.filter(group => allUsersPlaying.some(user => user.groupId === group.id));

  return (
    <div className="sidebar">
      {allUsersPlaying.length !== 0 && <>
        <h2 className="m-6 text-white font-bold text-lg">{quiz ? "Participants" : "En attente de participants..."}</h2>
        <EntitiesInfo
          groups={populatedGroups}
          users={allUsersPlaying}
          quiz={quiz}
          currentUser={currentUser}
          voteeId={voteeId}
          typingUsers={typingUsers}
          infoContext={infoContext}
        />
      </>}
      {/* {soloUsers.length !== 0 && <>
        <h2 className="m-6 text-white font-bold text-lg">{quiz ? "Participants" : "En attente de participants..."}</h2>
        <UsersInfo
          users={soloUsers}
          quiz={quiz}
          currentUser={currentUser}
          typingUsers={typingUsers}
        />
      </>}
      {populatedGroups.length !== 0 && <>
        <h2 className="m-6 text-white font-bold text-lg">{quiz ? "Groupes" : "En attente de groupes..."}</h2>
        <GroupsInfo
          groups={populatedGroups}
          users={allUsersPlaying}
          quiz={quiz}
          currentUser={currentUser}
          typingUsers={typingUsers}
        />
      </>} */}
    </div>
  )
};
