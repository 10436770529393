import './Users.css';


import React from 'react';

import { User } from '../app/utilities/types';

// TODO REMOVE
// import Lottie from 'react-lottie';
// import emojiKissingHeart from '../lottie/users/default/emoji-kissing-heart.json';
// import emojiSmileyCat from '../lottie/users/default/emoji-smiley-cat.json';

import UserAvatar from './UserAvatar';

interface UsersProps {
  users: User[],
  focusUserId?: string,
}

const Users: React.FC<UsersProps> = ({users, focusUserId}) => {
  return (
    <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-around", alignItems: "flex-start", flex: 1}}>
      {users.map((user, i) =>
        <UserAvatar
          key={user.id}
          avatarId={user.avatarId}
          name={user.name}
          hasFocus={user.id === focusUserId}
        />
      )}
    </div>
  );
}

export default Users;
