import {
  IonCard,
  IonButton,
  InputCustomEvent,
} from '@ionic/react';

import './PreLogin.css';

import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { RootState } from '../app/utilities/types';
import { Role } from '../app/utilities/types';
import { validateRoomCode } from '../app/utilities/validation';
import { TextField } from '../components/TextField';
import { preLogin, login, setAutoLogin } from '../store/auth.slice';
import { resetState as resetMainState } from '../store/main.slice';
import { resetError as resetQuizSessionError, setVerifyingQuizSessionCode } from '../store/quizSession.slice';
import { resetState as resetQuizState } from '../store/quiz.slice';

export interface FormFields {
  roomCode: string,
  name?: string,
  role: Role,
  isPlaying: boolean,
};

export interface FormErrors {
  roomCode?: string,
};

const makeLoginUser = (loginInfo: FormFields) => ({
  // This will be emitted by the server in response to the login message
  id: "",
  groupId: undefined,
  roomId: loginInfo.roomCode,
  // This could be undefined for prelogin but should not be for login
  name: loginInfo.name as string,
  role: loginInfo.role,
  isPlaying: loginInfo.isPlaying,
});

function useLoginInfo() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const roomCode = params.get("code") ?? "";
  const name = params.get("name") ?? undefined;
// TODO TEMP!!!  const role = params.get("role") === "admin" ? "admin" : "user";

// TODO TEMP!!!  const isPlaying = Boolean(Number(params.get("is-playing") ?? (role !== "admin")));

// TODO TEMP!!!
let role: "admin" | "user" = params.get("role") === "admin" ? "admin" : "user";
let isPlaying;
if (roomCode !== "") {
  role = "admin";
  isPlaying = false;
} else {
  isPlaying = Boolean(Number(params.get("is-playing") ?? (role !== "admin")));
}

  return useState<FormFields>({
    roomCode,
    name,
    // TODO More robust validation, perhaps digital sign this login info so tamper proof
    role,
    isPlaying,
  });
}

const PreLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loginInfo, setLoginInfo] = useLoginInfo();

  const doPreLogin = useCallback((loginInfo: FormFields) => {
    // TODO WOULD THIS BE BETTER DONE INSIDE AUTH.preLogin (OR main state slice) ?!?
    dispatch(resetMainState());
    dispatch(setVerifyingQuizSessionCode(true));
    dispatch(resetQuizState());
    dispatch(preLogin(makeLoginUser(loginInfo)));
  }, [dispatch]);

  const doLogin = useCallback((loginInfo: FormFields) => {
    dispatch(login(makeLoginUser(loginInfo)));
    dispatch(setAutoLogin(false));
  }, [dispatch]);

  const [errors, setErrors] = useState<FormErrors>({});

  const {isAutoLogin, roomId} = useSelector((state: RootState) => state.authState);
  const {
    quizSession,
    loading: quizSessionLoading,
    error: quizSessionError,
  } = useSelector((state: RootState) => state.quizSessionState);

  // useEffect(() => {
  //   if (roomId != null) {
  //     dispatch(getGroups(roomId));
  //   }
  // }, [dispatch, roomId]);

  if (loginInfo.roomCode !== "" && !!loginInfo.name) {
    dispatch(setAutoLogin(true));
  }

  useEffect(() => {
    if (isAutoLogin) {
      doPreLogin(loginInfo);
    }
  }, [isAutoLogin, loginInfo, doPreLogin]);

  useEffect(() => {
    //console.log(`IN PRELOGIN useEffect============>quizSessionError=${quizSessionError} quizSession=${JSON.stringify(quizSession)} isAutoLogin=${isAutoLogin}`);
    if (quizSession && !quizSessionError) {
      //? THESE SHOULDN'T BE NECESSARY AS socket.on('user connected') LISTENER HANDLER DOES THIS ALSO???
/*
      dispatch(setVerifyingQuizSessionCode(false));
      dispatch(resetQuizSessionError());
*/
      if (isAutoLogin) {
        doLogin(loginInfo);

        // if (currentUser?.role === "admin" && !currentUser?.isPlaying) {
        //   history.replace('/lobby');
        // } else {
          history.replace('/vote');
        // }
      }
      else {
        history.replace('/login');
      }
    }
  }, [quizSession, quizSessionError, isAutoLogin, loginInfo, doLogin, history]);

  const validate = (name: string, value: string) => {
    switch (name) {
      case 'roomCode':
        setErrors({ ...errors, [name]: validateRoomCode(value) });
        break;
    }
  };

  const isLoginButtonDisabled = () => {
    return !loginInfo.roomCode || !!errors.roomCode;
  };

  //! const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const handleInputChange = (event: InputCustomEvent) => {
    const { name, value } = event.target;

    setLoginInfo(loginInfo => ({
      ...loginInfo,
      [name]: value,
    }));

    if (errors[name as keyof FormErrors]) {
      validate(name, value as string);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    validate(name, value);
  };

  //! const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
  const handleSubmit = (event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
    //! event.preventDefault();
    doPreLogin(loginInfo);
  }

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      margin: "0 auto",
      width: "10%",
      minWidth: 300,
      height: "100%",
      textAlign: "center",
    }}>
      <IonCard style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        // margin: "0 auto",
        // width: "10%",
        // minWidth: 800,
      }}>
        <TextField
          label=""
          name="roomCode"
          value={loginInfo.roomCode}
          placeholder="Saisir le code"
          autofocus
          onChange={handleInputChange}
          onBlur={handleBlur}
          // error={errors.roomCode}
          style={{
            margin: "15px 0",
            fontSize: "2.3rem",
            textAlign: "center",
            background: "#eee",
            color: "#333",
          }}
        />

        {quizSessionLoading &&
          <div style={{width: "fit-content", margin: "20px auto 5px", fontSize: "1.1rem", borderRadius: 20, padding: "2px 8px", color: "#f93", border: "2px solid currentColor"}} className="loading">
            Vérification du code en cours...
          </div>
        }
        {quizSessionError &&
          <div style={{width: "fit-content", margin: "20px auto 5px", fontSize: "1.1rem", borderRadius: 20, padding: "2px 8px", color: "#e00", border: "2px dotted currentColor"}} className="error">
            {quizSessionError}
          </div>
        }

        <IonButton disabled={isLoginButtonDisabled()} onClick={handleSubmit} expand="block" style={{margin: 15}}>
          Valider
        </IonButton>
      </IonCard>
    </div>
  );
};

export default PreLogin;
