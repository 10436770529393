//! import axios from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Quiz, QuizState, QuestionAnswersOnly } from '../app/utilities/types';
//! import { setVoteeId } from './main.slice';

const quizSlice = createSlice({
  name: 'quiz',
  initialState: {
    quiz: null,
    quizDone: false,
    allQuizzesDone: false,
    quizHistory: [],
    prevQuizHistory: [],
    quizzesHistory: [],
    voteHistory: [],
    loading: false,
    error: null,
  } as QuizState,
  reducers: {
    // TODO USE getInitialState()
    resetState: (state) => {
      state.quiz = null;
      state.quizDone = false;
      state.allQuizzesDone = false;
      state.quizHistory = [];
      state.prevQuizHistory = [];
      state.quizzesHistory = [];
      state.voteHistory = [];
      state.loading = false;
      state.error = null;
    },
    setQuiz: (state, {payload}: PayloadAction<Quiz>) => {
      console.log(`setQuiz: payload=${JSON.stringify(payload)}`);
      state.quiz = payload;
      state.quizDone = false;
/*
      // Init quiz questions' answers
      state.quizzes = payload.map(quiz => ({
        ...quiz,
        questions: quiz.questions.map((question, index) => ({...question, id: String(index + 1), answers: []})),
      }));
*/
    },
    setQuizStartTime: (state, {payload: startTime}: PayloadAction<number>) => {
      if (state.quiz) {
        state.quiz.startTime = startTime;
      }
    },
    resetQuizStartTime: (state) => {
    },
    setQuizDone: (state, {payload: quizDone = true}: PayloadAction<boolean | undefined>) => {
      state.quizDone = quizDone;
    },
    setAllQuizzesDone: (state, {payload: allQuizzesDone = true}: PayloadAction<boolean | undefined>) => {
      state.allQuizzesDone = allQuizzesDone;
    },
    setQuizHistory: (state, {payload: quizHistory}: PayloadAction<any[]>) => {
      state.prevQuizHistory = state.quizHistory;
      state.quizHistory = quizHistory;
    },
    setQuizzesHistory: (state, {payload: quizzesHistory}: PayloadAction<any[]>) => {
      state.quizzesHistory = quizzesHistory;
    },
    setVoteHistory: (state, {payload: voteHistory}: PayloadAction<any[]>) => {
      state.voteHistory = voteHistory;
      console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ SETTING voteHistory=", voteHistory);
    },
/*
    updateQuiz: (state, {payload}: PayloadAction<Quiz>) => {
      state.quiz = payload;
      console.log(`updateQuiz: payload=${JSON.stringify(payload)}`);
    },
*/
    setLoading: (state) => {
      state.loading = true;
    },
    setLoadingComplete: (state) => {
      state.loading = false;
    },
    setAnswer: (state, {payload}: PayloadAction<any>) => {
      const {questionId, userId, choice, createdAt} = payload;

      // TODO HOW TO JUST CHANGE THE ANSWER DIRECTLY (MUTATE THE state.quiz) ???
      //! THIS IS A LOT OF WORK AND NOT PARTICULARLY EFFICIENT?!
      //TODO SEE https://redux.js.org/tutorials/fundamentals/part-8-modern-redux - SHOULD BE ABLE TO MUTATE STATE HERE INSIDE createSlice WITHOUT DOING ALL THIS PALAVER???
      if (state.quiz !== null) {
        state.quiz = {
          ...state.quiz,
          questions: state.quiz.questions.map(question => {
            let answers = question.answers;
            if (question.id === questionId) {
              answers = answers.filter(answer => answer.userId !== userId);
              answers.push({userId, choice, createdAt});
            }
            return {...question, answers};
          }),
        };
      }

      console.log(`setAnswer: payload=${JSON.stringify(payload)} state.quiz=${JSON.stringify(state.quiz)}`);
    },
    setAnswerSilently: (state, {payload}: PayloadAction<any>) => {
      const {questionId, userId, choice, createdAt} = payload;

      // TODO HOW TO JUST CHANGE THE ANSWER DIRECTLY (MUTATE THE state.quiz) ???
      //! THIS IS A LOT OF WORK AND NOT PARTICULARLY EFFICIENT?!
      //TODO SEE https://redux.js.org/tutorials/fundamentals/part-8-modern-redux - SHOULD BE ABLE TO MUTATE STATE HERE INSIDE createSlice WITHOUT DOING ALL THIS PALAVER???
      if (state.quiz !== null) {
        state.quiz = {
          ...state.quiz,
          questions: state.quiz.questions.map(question => {
            let answers = question.answers;
            if (question.id === questionId) {
              answers = answers.filter(answer => answer.userId !== userId);
              answers.push({userId, choice, createdAt});
            }
            return {...question, answers};
          }),
        };
      }

      console.log(`setAnswerSilently: payload=${JSON.stringify(payload)} state.quiz=${JSON.stringify(state.quiz)}`);
    },
    removeAnswer: (state, {payload}: PayloadAction<any>) => {
      const {questionId, userId, abstained = false} = payload;

      // TODO HOW TO JUST CHANGE THE ANSWER DIRECTLY (MUTATE THE state.quiz) ???
      //! THIS IS A LOT OF WORK AND NOT PARTICULARLY EFFICIENT?!
      //TODO SEE https://redux.js.org/tutorials/fundamentals/part-8-modern-redux - SHOULD BE ABLE TO MUTATE STATE HERE INSIDE createSlice WITHOUT DOING ALL THIS PALAVER???
      if (state.quiz !== null) {
        state.quiz = {
          ...state.quiz,
          questions: state.quiz.questions.map(question => {
            let answers = question.answers;
            if (question.id === questionId) {
              answers = answers.filter(answer => answer.userId !== userId);
              if (abstained) {
                answers.push({userId, abstained});
              }
            }
            return {...question, answers};
          }),
        };
      }

      console.log(`removeAnswer: payload=${JSON.stringify(payload)} state.quiz=${JSON.stringify(state.quiz)}`);
    },
    removeUserAnswers: (state, {payload}: PayloadAction<string>) => {
      const userId = payload;
      if (state.quiz !== null) {
        state.quiz = {
          ...state.quiz,
          questions: state.quiz.questions.map(question => ({
            ...question,
            ...{answers: question.answers.filter(answer => answer.userId !== userId)},
          })),
        };
      }
      console.log(`userId=${JSON.stringify(userId)} removeUserAnswers: state.quiz=${JSON.stringify(state.quiz)}`);
    },

// setAnswer: (state, {payload}: PayloadAction<any>) => {
// const {userId, questionId, choice} = payload;

    setQuestionsAnswersOnly: (state, {payload}: PayloadAction<any>) => {
      const questionsAnswersOnly: QuestionAnswersOnly[] = payload.questionsAnswersOnly;
      const oldUserId: string = payload.oldUserId;

      questionsAnswersOnly.forEach(questionAnswers => {
        questionAnswers.answers.forEach((questionAnswer) => {
//TODO EFFICIENCY ???
/*
          setAnswer({payload: {
            questionId: question.id,
            userId,
            choice,
          }});
*/
//TODO DRY!!!
const questionId = questionAnswers.id;
// TODO HOW TO JUST CHANGE THE ANSWER DIRECTLY (MUTATE THE state.quiz) ???
//! THIS IS A LOT OF WORK AND NOT PARTICULARLY EFFICIENT?!
//TODO SEE https://redux.js.org/tutorials/fundamentals/part-8-modern-redux - SHOULD BE ABLE TO MUTATE STATE HERE INSIDE createSlice WITHOUT DOING ALL THIS PALAVER???
if (state.quiz !== null) {
  state.quiz = {
    ...state.quiz,
    questions: state.quiz.questions.map(question => {
      let answers = question.answers;
      if (question.id === questionId) {
//!        answers = answers.filter(answer => answer.userId !== questionAnswer.userId);
        answers = answers.filter(answer => answer.userId !== oldUserId);
        answers.push(questionAnswer);
      }
      return {...question, answers};
    }),
  };
}

        });
      });
/*
      if (state.quiz !== null) {
        state.quiz = {
          ...state.quiz,
          questions: state.quiz.questions.map(question => {
const answer = questionAnswersOnly.find(answer => answer)
            let answers = question.answers;
            if (question.id === questionId) {
              answers = answers.filter(answer => answer.userId !== userId);
              answers.push({userId, choice});
            }
            return {...question, answers};
          }),
        };
      }
*/
      console.log(`setQuestionAnswersOnly: questionAnswersOnly=`, questionsAnswersOnly);
      //
    },
  },
});

export const {
  resetState,
  setQuiz,
  setQuizStartTime,
  resetQuizStartTime,
  setQuizDone,
  setAllQuizzesDone,
  setQuizHistory,
  setQuizzesHistory,
  setVoteHistory,
  //! updateQuiz,
  setLoading,
  setLoadingComplete,
  setAnswer,
  setAnswerSilently,
  removeAnswer,
  removeUserAnswers,
  setQuestionsAnswersOnly,
} = quizSlice.actions;

export default quizSlice.reducer;

// Action
/*
export function initQuiz(quizNotBegun: boolean) {
  return async (dispatch: Dispatch, getState: () => {}) => {
    console.log('initQuiz: quizNotBegun=', quizNotBegun);

    dispatch(setLoading());

    try {
      if (quizNotBegun) {
        const { data: {voteeId} } = await axios.post('/api/begin-quiz');
        dispatch(setVoteeId(voteeId));
      }

      // TODO HOW TO CALL getCurrentQuiz() ACTION INSIDE OF THIS ACTION?
      // dispatch(getCurrentQuiz());
      const { data } = await axios(`/api/quiz`);
      dispatch(setQuiz(data.quiz));

//? dispatch(mainSlice.actions.setVoteeId(voteeId));
    } catch (e) {
      // Not handling errors
      console.log(e);
    } finally {
      dispatch(setLoadingComplete());
    }
  };
};
*/

// TODO MAYBE initQuiz ABOVE COULD JUST DO THIS (/api/begin-quiz and /api/next-votee) ???
//! NO LONGER USED!
/*
export function getCurrentQuiz(roomId: string) {
  return async (dispatch: Dispatch, getState: () => {}) => {
    console.log('getCurrentQuiz');

    dispatch(setLoading());
    try {
      const { data } = await axios(`/api/${roomId}/quiz`);
      dispatch(setQuiz(data.quiz));
    } catch (e) {
      // Not handling errors
      console.log(e);
    } finally {
      dispatch(setLoadingComplete());
    }
  }
}
*/
