import { combineReducers, Reducer } from 'redux';

import { RootState } from '../app/utilities/types';
import mainReducer from './main.slice';
import authReducer from './auth.slice';
import usersReducer from './users.slice';
import groupsReducer from './groups.slice';
import quizReducer from './quiz.slice';
import quizSessionReducer from './quizSession.slice';
import messagesReducer from './messages.slice';

export const rootReducer: Reducer<RootState> = combineReducers<RootState>({
  mainState: mainReducer,
  authState: authReducer,
  usersState: usersReducer,
  groupsState: groupsReducer,
  quizState: quizReducer,
  quizSessionState: quizSessionReducer,
  messagesState: messagesReducer,
});
