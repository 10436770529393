import { User, Group, Quiz, InfoContext } from '../app/utilities/types';
import { EntitiesInfo } from './EntitiesInfo';

interface AdminSidebarProps {
  users: User[],
  groups: Group[],
  quiz: Quiz | null,
  currentUser: User | null,
  voteeId?: string,
  typingUsers: string[],
  infoContext: InfoContext,
}

export const AdminSidebar: React.FC<AdminSidebarProps> = ({ users, groups, quiz, currentUser, voteeId, typingUsers, infoContext }) => {
  const allUsersPlaying = users.filter(user => user.isPlaying);
  //! const soloUsers = allUsersPlaying.filter(user => !user.groupId);
  const populatedGroups = groups.filter(group => allUsersPlaying.some(user => user.groupId === group.id));

  return (
    <div className="admin-sidebar sidebar">
      {allUsersPlaying.length !== 0 && <>
        <h2 className="m-6 text-white font-bold text-lg">{quiz ? "Participants" : "En attente de participants..."}</h2>
        <EntitiesInfo
          groups={populatedGroups}
          users={allUsersPlaying}
          quiz={quiz}
          currentUser={currentUser}
          voteeId={voteeId}
          typingUsers={typingUsers}
          infoContext={infoContext}
        />
      </>}
    </div>
  )
};
