import { includes } from 'lodash';
import { useRef } from 'react';

export default function usePointingDevice(
    handlePressStart: any,
    handleLongPressStart: any,
    handlePressEnd: any,
    handlePress: any,
    handleQuickPress: any,
    handleDoublePress: any,
    handleDrag: any,
  )
{
  const action = useRef('');

  const timerRef = useRef<any>(null);
  const timerRef2 = useRef<any>(null);
  const ignoreMouseEventsTimerRef = useRef<any>(null);
  const ignoreQuickPressTimerRef = useRef<any>(null);

  const pressStartTime = useRef<number>(0);
  const prevPressStartTime = useRef<number>(0);

  const prevPointerOffset = useRef<{x: number, y: number}>({x: 0, y: 0});
  const dragSourceElement = useRef<any>(null);

  function startPressTimer(event: any, isTouching: boolean) {
    action.current = '';

    const _event = {
      currentTarget: event.currentTarget,
    };

    handlePressStart(_event, isTouching);

    // Used for detecting double clicks and touches
    prevPressStartTime.current = pressStartTime.current;
    pressStartTime.current = Date.now();

    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      timerRef.current = null;
      handleLongPressStart(_event);
      action.current = 'longpress';
    }, 500);

    const interval = pressStartTime.current - prevPressStartTime.current;
    // console.log(`check DOUBLE TOUCH: interval=${interval} pressStartTime.current=${pressStartTime.current} prevPressStartTime.current=${prevPressStartTime.current}`);
    if (interval < 300) {
      // console.log('DOUBLE TOUCHED!');
      clearTimeout(timerRef2.current);
      handleDoublePress(_event);

      // PUT DELAY HERE UNTIL CAN PROCESS ANOTHER QUICKPRESS (OTHERWISE ITS LOGIC MAY INTERFER WITH THAT OF THE DOUBLE CLICK)
      clearTimeout(ignoreQuickPressTimerRef.current);
      ignoreQuickPressTimerRef.current = setTimeout(() => {
        ignoreQuickPressTimerRef.current = null;
      }, 1000);
    }
    else if (interval < 750 && ignoreQuickPressTimerRef.current === null) {
      clearTimeout(timerRef2.current);
      handleQuickPress(_event);
    }
    else {
      // TODO DELAY THIS BY 300ms (TO GIVE DOUBLEPRESS A CHANCE TO GET FIRST DIBS) ???
      clearTimeout(timerRef2.current);
      timerRef2.current = setTimeout(() => {
          timerRef2.current = null;
          action.current = 'press';
          handlePress(_event);
      }, 300);
    }
  }

  // TODO ALL THIS SHOULD BE A CALLBACK TO KEEP THIS HOOK GENERIC

/*
  function handleOnClick(event: any) {
//      console.log('in handleOnClick: longPressedIndex=' + longPressedIndex);
  //! if (longPressedIndex !== -1) {
if (action.current === 'longpress') {
      console.log('Is long press - not continuing.');
      return;
    }
    action.current = 'click';
  }
*/

  function handleOnMouseDown(event: any) {
    // console.log('handleOnMouseDown');
    if (ignoreMouseEventsTimerRef.current) {
      // console.log('handleOnMouseDown IGNORED');
      return;
    }
    startPressTimer(event, false);
  }

  function handleOnMouseUp(event: any) {
    // console.log('handleOnMouseUp');
    if (ignoreMouseEventsTimerRef.current) {
      // console.log('handleOnMouseUp IGNORED');
      return;
    }
    invokePressEnd(event);
  }

  function invokePressEnd(event: any) {
    clearTimeout(timerRef.current);
    timerRef.current = null;
    //?
    action.current = "";
    dragSourceElement.current = null;

    handlePressEnd(event);
  }

  function handleOnTouchStart(event: any) {
    // console.log('handleOnTouchStart');
    // TODO EXPLAIN WHY THIS IS NEEDED
    // TODO IF A SHORT TOUCH IS DONE THEN THE TOUCHSTART THEN TOUCHEND EVENTS FIRE SUCCESSIVELY,
    // TODO FOLLOWED BY MOUSEUP AND MOUSEDOWN ONES WHICH MUST BE SUPPRESSED! THIS DOESN'T HAPPEN FOR LONG(ISH) TOUCHES HOWEVER?!?
    // Prevent this from getting done twice when touching as a mouse down is also triggered by the browser
    clearTimeout(ignoreMouseEventsTimerRef.current);
    ignoreMouseEventsTimerRef.current = setTimeout(() => {
      ignoreMouseEventsTimerRef.current = null;
      // console.log('>>>>>>>>>>>>>> handleOnTouchStart TIMEOUT ignoreMouseEventsTimerRef.current=' + ignoreMouseEventsTimerRef.current);
    }, 1000); //? ONE SEC SHOULD ENSURE NO MOUSEEVENT GETS IN STRAIGHT AFTER A TOUCH ONE
    // console.log('handleOnTouchStart ignoreMouseEventsTimerRef.current=' + ignoreMouseEventsTimerRef.current);
    startPressTimer(event, true);
  }

  function handleOnTouchEnd(event: any) {
    // console.log('handleOnTouchEnd');
    invokePressEnd(event);
  }

  // function handleOnMouseEnter(event: any) {
  //   console.log('handleOnMouseEnter');
  // }

  function handleOnMouseMove(event: any) {
    // console.log('handleOnMouseMove');

    // if (["press", "longpress"].includes(action.current)) {
    if (["dragging-longpress-cancelled", "longpress"].includes(action.current)) {
    //? if (["longpress"].includes(action.current)) {
      handleDragging(event, {x: event.clientX, y: event.clientY}, false);
    }
  }

  function handleOnTouchMove(event: any) {
    // console.log('handleOnTouchMove');

    // Deactivate scrolling when moving
    // event.stopPropagation();

    const touch = event.touches[0];
    // console.log('handleOnTouchMove touch=',touch);

    handleDragging(event, {x: touch.clientX, y: touch.clientY}, true);
  }


  // TODO PARTS OF THIS SHOULD TO BE IN THE CLIENT CODE
  function handleDragging(event: any, pointerOffset: {x: number, y: number}, isTouching: boolean) {
    if (dragSourceElement.current === null) {
      // console.log("handleDragging: got new event.currentTarget=",event.currentTarget,"dragSourceElement.current=",dragSourceElement.current);
      dragSourceElement.current = event.currentTarget;
      // prevPointerOffset.current = pointerOffset;
      // TODO PERHAPS RESTORE STAR STATE AS WAS BEFORE INITIALLY LONG PRESSED (AS THIS 'FLIPS' IT)
    }
else
    // Check movement threshold large enough
    if (Math.abs(prevPointerOffset.current.x - pointerOffset.x) < 5) {
      return;
    }

    prevPointerOffset.current = pointerOffset;

    let target: any = document.elementFromPoint(pointerOffset.x, pointerOffset.y);

    //const isSvgOrWithin = ["svg", "g", "path"].includes(target.tagName);
    //if (isSvgOrWithin) {
    do {
      //console.log("tagName=" + target.tagName);
      const ratingIndex = target.getAttribute('data-rating-index');
      if (ratingIndex != null) {
        //console.log("Moved into ratingIndex=" + Number(ratingIndex) + " target=", target);
        break;
      }
      target = target.parentNode;
      if (target?.tagName === "BODY") {
        target = null;
      }
    } while (target);
    //}

    // if (dragSourceElement.current) dragSourceElement.current.style.border = null;
    // target.style.border = "1px solid red";

    // TODO HOW TO CHECK IF TARGETED ELEMENT IS A RELATED RATING (I.E. IN SAME GROUP)
    if (target) {
      //? SO CANCELS POTENTIAL LONGPRESS
      // TODO NEED TO DEACTIVATE THIS WHEN "LOSES FOCUS" (ALTHOUGH TOUCH ELEMENTS DON'T LOSE FOCUS AS SUCH)
      const changeFocusWhenEnterNewTarget = !isTouching || action.current !== "longpress";
      if (changeFocusWhenEnterNewTarget) {
        if (target !== dragSourceElement.current) {
          //handleOnTouchEnd(event);
          const _event = {
            currentTarget: dragSourceElement.current,
          };

          invokePressEnd(_event);

          //! Transitioning to "press" causes selections to be sticky on hover
          //! action.current = "press";
          action.current = "dragging-longpress-cancelled";

          dragSourceElement.current = target;

          // handleOnTouchStart(_event);
        }
      }

      let normalOffset = {x: 1, y: 1}; // Set to 1 so whole icon will be selected by default
      //if (target === dragSourceElement.current) { // USE TO ENABLE CONTINUOUS FRACTION VOTING (NOT JUST FOR LONG CLICKS)
      if (action.current === "longpress") {
        const rect = dragSourceElement.current.getBoundingClientRect();
        const offsetX = pointerOffset.x - rect.left;
        const offsetY = pointerOffset.y - rect.top;
        // TODO PROB voteRatingSmallestFraction IS SET BY CLIENT CODE
        //? const ratingFractionMidpoint = isTouching ? (1 / 4) / 2 : 0; // The ratings have 4 fractional parts, so this is their midpoint
        const ratingFractionMidpoint = (1 / 4) / 2; // The ratings have 4 fractional parts, so this is their midpoint
        normalOffset.x = Math.min(Math.max(offsetX / rect.width - ratingFractionMidpoint, 0), 1);
        normalOffset.y = Math.min(Math.max(offsetY / rect.height, 0), 1);

        // console.log('range offsetX=', offsetX, 'normalOffsetX=', normalOffset.x, 'target rect=', rect);
      }

      const _event = {
        currentTarget: dragSourceElement.current,
      };

      // From startPressTimr
      if (action.current !== "longpress" && timerRef.current === null) {
        timerRef.current = setTimeout(() => {
          timerRef.current = null;
          handlePressStart(_event, isTouching);
          handleLongPressStart(_event);
          action.current = 'longpress';
        }, isTouching ? 500 : 500 * 3);
      }

      // handlePressStart(_event);

      handleDrag(_event, normalOffset);

      // handlePressEnd(_event);
    }
    else {
      prevPointerOffset.current = {x: 0, y: 0};

      // Needed to deselect first vote icon when pointer not over any icon
      if (dragSourceElement.current) {
        const ratingIndex = dragSourceElement.current.getAttribute('data-rating-index');
        const isFirstIcon = ratingIndex === "0";
        if (isFirstIcon) {
          let normalOffset = {x: 0, y: 0};
          const _event = {
            currentTarget: dragSourceElement.current,
          };
          handleDrag(_event, normalOffset);
          // dragSourceElement.current = null;
        }
        dragSourceElement.current = null;
      }
    }
  }

  // Disable navigator's context menu
  function handleOnContextMenu(event: any) {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  return {
    action,
    handlers: {
      // onClick: handleOnClick,
      // onDoubleClick: handleOnDoubleClick,
      onMouseDown: handleOnMouseDown,
      onMouseUp: handleOnMouseUp,
      // onMouseEnter: handleOnMouseEnter,
      onTouchStart: handleOnTouchStart,
      onTouchEnd: handleOnTouchEnd,
      onTouchMove: handleOnTouchMove,
      onMouseMove: handleOnMouseMove,
      // HS
      onContextMenu: handleOnContextMenu,
    },
  };
}
